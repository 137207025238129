const LoadingSVG = () => {
  return (
    <svg viewBox="25 25 50 50">
      <circle
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeDasharray="89, 200"
        strokeDashoffset="0"
      />
    </svg>
  );
};

export {LoadingSVG};
