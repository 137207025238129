import {useMask} from '@react-input/mask';
import {Input} from 'src/atoms/Input/Input';
import {StyledAssistiveText, StyledLabel} from 'src/atoms/Input/styled';
import {Dropdown} from 'src/molecules/Dropdown/Dropdown';
import {IntRange} from 'type-fest';

import type {InputProps} from 'src/atoms/Input/Input';
import type {DropdownItemProps} from 'src/molecules/Dropdown/Dropdown';

import {StyledContainer, StyledPhoneField} from './styled';

export interface PhoneFieldProps extends InputProps {
  label?: string;
  floatingLabel?: boolean;
  spacing?: IntRange<0, 100>;
  mask?: string;
  countryList?: Array<DropdownItemProps>;
}

const PhoneField = ({
  label = '',
  floatingLabel = true,
  spacing = 0,
  mask = '__ _____________',
  countryList = [],
  ...props
}: PhoneFieldProps) => {
  const inputRef = useMask({
    mask,
    replacement: {_: /\d/},
  });

  const $hasCountryList = countryList.length > 0;

  return (
    <StyledContainer $spacing={spacing}>
      <StyledPhoneField $hasCountryList={$hasCountryList}>
        {label !== '' && !floatingLabel && (
          <StyledLabel htmlFor={props.name} $floating={floatingLabel}>
            {label}
          </StyledLabel>
        )}

        {$hasCountryList && <Dropdown placeholder="Code" items={countryList} />}

        <Input type="tel" pattern="[0-9]*" ref={inputRef} floatingLabel={floatingLabel} {...props} />

        {label !== '' && floatingLabel && (
          <StyledLabel $floating={floatingLabel} data-floating={true}>
            {label}
          </StyledLabel>
        )}
      </StyledPhoneField>

      {props?.error && <StyledAssistiveText>{props.error}</StyledAssistiveText>}
    </StyledContainer>
  );
};

export {PhoneField};
