import {DefaultTransition} from 'src/styled/transitions';
import {ptToPx} from 'src/styled/utils';
import styled from 'styled-components';

interface ContainerProps {
  $spacing: number;
}

const StyledSearchIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 16px;
  z-index: var(--model-zindex-base);
  transform: translateY(-50%);
`;

const StyledClearSearch = styled.button`
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: var(--model-zindex-base);
  width: 24px;
  height: 24px;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-50%);
  ${DefaultTransition()};
`;

const StyledSearchField = styled.div<ContainerProps>`
  position: relative;
  margin-bottom: ${({$spacing}: ContainerProps) => `${ptToPx($spacing)}px`};

  input {
    padding-right: 56px;
    padding-left: 56px;

    &.filled {
      & ~ ${StyledClearSearch} {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;

export {StyledSearchField, StyledClearSearch, StyledSearchIcon};
