import {MouseEvent as ReactMouseEvent} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';

import {StyledIconContainer, StyledKastaTag} from './styled';

export interface KastaTagProps {
  username: string;
  url?: string;
  onClick?: (e: ReactMouseEvent) => void;
  disabled?: boolean;
}

const KastaTag = ({
  url = '',
  username = '',
  onClick = () => null,
  disabled = false,
  ...props
}: KastaTagProps) => {
  if (url !== '') {
    return (
      <StyledKastaTag as="a" href={url} disabled={disabled} {...props}>
        <StyledIconContainer>
          <Icon name="user" size={20} />
        </StyledIconContainer>

        <p>{username}</p>
      </StyledKastaTag>
    );
  }

  return (
    <StyledKastaTag onClick={onClick} disabled={disabled} {...props}>
      <StyledIconContainer>
        <Icon name="user" size={20} />
      </StyledIconContainer>

      <p>{username}</p>
    </StyledKastaTag>
  );
};

export {KastaTag};
