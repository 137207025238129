import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {TOAST_ANIMATION_TIMEOUT} from 'src/lib/constants';

import {StyledContainer} from './styled';
import {Toast, ToastProps} from './Toast';

export interface ToastContainerProps {
  toasts: Array<ToastProps>;
}

const ToastContainer = ({toasts = [], ...props}: ToastContainerProps) => (
  <TransitionGroup className="toast-container">
    <StyledContainer {...props}>
      {toasts.map(({id = 'toast-0', text = '', ...props}: ToastProps) => (
        <CSSTransition
          key={id}
          in={true}
          timeout={TOAST_ANIMATION_TIMEOUT}
          classNames="toast"
          unmountOnExit
          appear
          enter>
          <Toast key={id} id={id} text={text} {...props} />
        </CSSTransition>
      ))}
    </StyledContainer>
  </TransitionGroup>
);

export {ToastContainer};
