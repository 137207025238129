import {useState} from 'react';

interface usePopUpProps {
  blockScroll?: boolean;
}

const usePopUp = ({blockScroll = true}: usePopUpProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const togglePopUp = () => {
    if (blockScroll) {
      if (!isVisible) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }

    setIsVisible(!isVisible);
  };

  return {
    isVisible,
    togglePopUp,
  };
};

export {usePopUp};
