import Image from 'next/image';
import {useRouter} from 'next/router';

import {Button, Dialog, Typography} from '@kasta-io/components';

import {handleContactSupport} from '@/utils/support';

import s from './KycActionNeededDialog.module.scss';

interface Props {
  type: 'cash' | 'crypto';
  isVisible: boolean;
  toggleDialog: () => void;
}

export const KycActionNeededDialog = ({type, isVisible, toggleDialog}: Props) => {
  const router = useRouter();

  const onResubmit = () => {
    toggleDialog();

    if (type === 'crypto') {
      return router.push(`/kyc`);
    }

    return router.push('/cash/activation');
  };

  const onContactSupport = () => {
    toggleDialog();
    handleContactSupport();
  };

  return (
    <Dialog open={isVisible} popOver onClose={toggleDialog} closeOnBackdropClick hasCloseButton={false}>
      <div className={s.container}>
        <Image src="/images/yellow-column.png" width={80} height={80} alt="" quality={100} />
        <Typography variant="h6" as="h6">
          Action Needed: <br />
          Resubmit information
        </Typography>

        <Typography variant="body2" color="var(--colors-text-onBackgroundMedium)">
          We couldn&apos;t verify your information. Please resubmit the required information to proceed.
        </Typography>

        <div className={s.buttonContainer}>
          <Button type={'secondary'} onClick={onContactSupport} block>
            Contact support
          </Button>
          <Button onClick={onResubmit} block>
            Resubmit
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
