import {forwardRef, MouseEvent as ReactMouseEvent, ReactNode, useRef} from 'react';
import {createPortal} from 'react-dom';
import {CSSTransition} from 'react-transition-group';

import {type ButtonProps} from 'src/atoms/Button/Button';
import {Icon} from 'src/atoms/Icon/Icon';
import {DIALOG_ANIMATION_TIMEOUT, DIALOG_PORTAL_NODE} from 'src/lib/constants';

import {StyledBackdrop, StyledCloseButton, StyledDialog, StyledDialogHeader} from './styled';

export interface DialogProps {
  open?: boolean;
  title?: ReactNode;
  hasCloseButton?: boolean;
  onClose?: (e: ReactMouseEvent) => void;
  closeButtonType?: ButtonProps['type'];
  mobileFullScreen?: boolean;
  children?: ReactNode;
  backdrop?: boolean;
  closeOnBackdropClick?: boolean;
  elevation?: boolean;
  selector?: string;
  popOver?: boolean;
}

const Content = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      title,
      elevation,
      hasCloseButton,
      children,
      onClose,
      closeButtonType,
      mobileFullScreen,
      popOver,
      ...props
    },
    ref,
  ) => (
    <StyledDialog
      ref={ref}
      $elevation={!!elevation}
      $mobileFullScreen={!!mobileFullScreen}
      $popOver={!!popOver}
      {...props}>
      {title !== '' || hasCloseButton ? (
        <StyledDialogHeader>
          {title !== '' && title}

          {hasCloseButton && <CloseButton onClick={onClose} type={closeButtonType} />}
        </StyledDialogHeader>
      ) : null}

      {children}
    </StyledDialog>
  ),
);

const CloseButton = ({
  onClick = () => null,
  type = 'primary',
}: {
  onClick?: (e: ReactMouseEvent) => void;
  type: DialogProps['closeButtonType'];
}) => (
  <StyledCloseButton size="xs" onClick={onClick} aria-label="Close dialog" type={type}>
    <Icon name="close-big" />
  </StyledCloseButton>
);

const Component = ({
  title = '',
  hasCloseButton = true,
  onClose = () => null,
  closeButtonType = 'primary-icon',
  closeOnBackdropClick = false,
  mobileFullScreen = false,
  children = null,
  elevation = true,
  backdrop = true,
  open = false,
  popOver = false,
  ...props
}: DialogProps) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  const onBackdropClickHandler = (event: ReactMouseEvent<Element, MouseEvent>): void => {
    if (closeOnBackdropClick) {
      if (!dialogRef?.current?.contains(event.target as Node)) {
        return onClose(event);
      }
    }
  };

  if (backdrop) {
    return (
      <CSSTransition
        in={open}
        timeout={DIALOG_ANIMATION_TIMEOUT}
        classNames="dialog"
        unmountOnExit
        appear
        enter>
        <StyledBackdrop role="presentation" onClick={event => onBackdropClickHandler(event)}>
          <Content
            ref={dialogRef}
            title={title}
            elevation={elevation}
            hasCloseButton={hasCloseButton}
            onClose={onClose}
            closeButtonType={closeButtonType}
            mobileFullScreen={mobileFullScreen}
            popOver={popOver}
            {...props}>
            {children}
          </Content>
        </StyledBackdrop>
      </CSSTransition>
    );
  }

  return (
    <Content
      ref={dialogRef}
      title={title}
      elevation={elevation}
      hasCloseButton={hasCloseButton}
      onClose={onClose}
      closeButtonType={closeButtonType}
      mobileFullScreen={mobileFullScreen}
      {...props}>
      {children}
    </Content>
  );
};

const Dialog = ({
  open = false,
  onClose = () => '',
  children,
  selector = `#${DIALOG_PORTAL_NODE}`,
  ...props
}: DialogProps) => {
  const ref = useRef<HTMLElement | DocumentFragment>(document.querySelector(selector) as HTMLElement);

  return (
    <>
      {open
        ? createPortal(
            <Component onClose={onClose} open={open} {...props}>
              {children}
            </Component>,
            ref.current,
          )
        : null}
    </>
  );
};

const DialogAsCard = ({title = '', hasCloseButton = false, ...props}: DialogProps) => {
  return <Component title={title} hasCloseButton={hasCloseButton} backdrop={false} {...props} />;
};

export {Dialog, DialogAsCard};
