import {HTMLProps} from 'react';

import {CUSTOM_ICONS, IMAGERY_ICONS, KASTA_ICONS} from 'src/lib/icons';

import type {Color as ColorType} from '@kasta-io/tokens/src/types';
import type {ArrayValues, IntRange} from 'type-fest';

import {StyledCustomIcon, StyledImageryIcon, StyledKastaIcon, StyledPaymentIcon} from './styled';

interface BaseIconProps extends HTMLProps<HTMLSpanElement> {
  label?: string;
  color?: ColorType;
  backgroundColor?: ColorType;
  size?: IntRange<0, 100>;
  as?: keyof JSX.IntrinsicElements;
}

export interface KastaIconProps extends BaseIconProps {
  type?: 'kastaicon';
  name: ArrayValues<typeof KASTA_ICONS>;
}

export interface CustomIconProps extends BaseIconProps {
  type?: 'custom';
  name: ArrayValues<typeof CUSTOM_ICONS>;
}

export interface ImageryIconProps extends BaseIconProps {
  type?: 'imagery';
  name: ArrayValues<typeof IMAGERY_ICONS>;
}

export interface PaymentIconProps extends BaseIconProps {
  type?: 'payment';
  name: ArrayValues<typeof IMAGERY_ICONS>;
}

const Icon = ({
  type = 'kastaicon',
  name = '',
  label = '',
  color = 'var(--colors-primary-500)',
  backgroundColor = 'var(--colors-main-secondary)',
  size = 16,
  ...props
}: KastaIconProps | CustomIconProps | ImageryIconProps | PaymentIconProps) => {
  if (type === 'payment') {
    return (
      <StyledPaymentIcon
        aria-hidden="true"
        role="icon"
        aria-label={label}
        $name={name}
        $size={size}
        {...props}
      />
    );
  }

  if (type === 'imagery') {
    return (
      <StyledImageryIcon
        aria-hidden="true"
        role="icon"
        aria-label={label}
        $name={name}
        $size={size}
        $background={backgroundColor}
        {...props}
      />
    );
  }

  if (type === 'custom') {
    return (
      <StyledCustomIcon
        aria-hidden="true"
        role="icon"
        aria-label={label}
        $name={name}
        $size={size}
        {...props}
      />
    );
  }

  return (
    <StyledKastaIcon
      aria-hidden="true"
      role="icon"
      className={`kastaicon-${name}`}
      aria-label={label}
      $color={color}
      $size={size}
      {...props}
    />
  );
};

export {Icon};
