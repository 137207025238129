import {createContext, ReactNode, useCallback, useState} from 'react';

import {ToastContainer} from 'src/molecules/Toast/Container';

import type {ToastProps} from 'src/molecules/Toast/Toast';

interface ToastProviderProps {
  children: ReactNode;
}

export interface ToastContextProps {
  addToast: (a: ToastProps) => void;
  removeToast: (a: string) => void;
}

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

const ToastProvider = ({children}: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Array<ToastProps>>([]);

  const addToast = useCallback(
    ({id = `new Date().getTime()`, text = 'Toast message', ...props}: ToastProps) => {
      setToasts(toasts => [
        ...toasts,
        {
          id,
          text,
          ...props,
        },
      ]);
    },
    [toasts, setToasts],
  );

  const removeToast = useCallback(
    (id: string) => {
      setToasts(toasts.filter((toast: ToastProps) => toast.id !== id));
    },
    [toasts, setToasts],
  );

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
      }}>
      {children}
      <ToastContainer toasts={toasts} />
    </ToastContext.Provider>
  );
};

export {ToastProvider, ToastContext};
