import type {GetUserQuery, PublishedUserAccountSubscription} from '@/graphql/__generated__/graphql';

type AccountGraphqlResponse = null | GetUserQuery | PublishedUserAccountSubscription;

export const afterGet = (user?: AccountGraphqlResponse) => {
  let balances = null;
  let currencyBalances: Record<string, any>[] = [];

  if (user && 'publishedUserAccount' in user) {
    const account = user.publishedUserAccount?.account;
    balances = account?.balances || null;

    currencyBalances =
      (account?.currencyBalances as Record<string, any>[] | undefined)
        ?.filter(balance => balance?.__typename === 'CurrencyBalance')
        ?.map(balance => ({
          currency: balance.currency || '',
          available: balance?.available?.toString() || '0',
          incoming: balance?.incoming?.toString() || '0',
          reserved: balance?.reserved?.toString() || '0',
          lockedUp: balance?.lockedUp?.toString() || '0',
        })) || [];
  }

  if (user && 'getUser' in user) {
    const account = user.getUser?.account;
    balances = account?.balances || balances;
    currencyBalances =
      (account?.currencyBalances?.filter(balance => balance !== null) as Record<string, any>[]) ||
      currencyBalances;
  }

  return {
    balances,
    currencyBalances,
  };
};
