import {css} from 'styled-components';

export const DefaultTransition = (properties: string = 'all') => {
  const easing = 'ease-in-out';
  const duration = '250ms';

  if (properties !== 'all') {
    const transition = properties.split(' ').map(property => `${property} ${easing} ${duration}`);

    return css`
      transition: ${transition.join(',')};
    `;
  }

  return css`
    transition: ${properties} ${easing} ${duration};
  `;
};

export const TransitionClasses = ({selector = ''}: {selector: string}) => css`
  &.${selector}-enter, &.${selector}-appear, &.${selector}-exit-active {
    opacity: 0;
  }

  &.${selector}-exit, &.${selector}-appear-active, &.${selector}-enter-active {
    opacity: 1;
  }

  &.${selector}-enter-active, &.${selector}-appear-active, &.${selector}-exit-active {
    ${DefaultTransition()}
  }
`;
