import viewports from '@kasta-io/tokens/primitives/viewport.json';
import {StyledInput} from 'src/atoms/Input/styled';
import {StyledContainer as StyledDropdownContainer} from 'src/molecules/Dropdown/styled';
import {ptToPx} from 'src/styled/utils';
import styled, {css} from 'styled-components';

interface ContainerProps {
  $spacing: number;
}

interface FieldProps {
  $hasCountryList: boolean;
}

const StyledContainer = styled.div<ContainerProps>`
  position: relative;
  margin-bottom: ${({$spacing}: ContainerProps) => `${ptToPx($spacing)}px`};
`;

const StyledPhoneField = styled.div<FieldProps>`
  position: relative;

  ${({$hasCountryList}: FieldProps) =>
    $hasCountryList &&
    css`
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;

      ${StyledDropdownContainer} {
        flex: 0 1 30%;

        button {
          border-right-width: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        @media screen and (min-width: ${viewports['tablet']}) {
          flex-basis: 130px;
        }
      }

      ${StyledInput} {
        flex: 1;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    `}
`;

export {StyledContainer, StyledPhoneField};
