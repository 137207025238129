import {Dialog} from 'src/molecules/Dialog/Dialog';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  position: relative;

  h6 {
    flex: 1;
    text-align: center;
  }
`;

const StyledContent = styled.div`
  position: relative;
`;

export {StyledDialog, StyledContent};
