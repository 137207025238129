import {cssVar, lighten} from 'polished';
import {DefaultTransition} from 'src/styled/transitions';
import styled, {css} from 'styled-components';

interface RadioProps {
  disabled?: boolean | undefined;
}

const StyledRadio = styled.input<RadioProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  appearance: none;
  cursor: pointer;
  border: 2px solid var(--colors-primary-500);
  border-radius: 50%;

  &,
  &:before {
    ${DefaultTransition()};
  }

  &:before {
    display: block;
    width: 10px;
    height: 10px;
    background-color: var(--colors-primary-500);
    border-radius: 50%;
    transform: scale(0);
  }

  &:checked {
    &:before {
      transform: scale(1);
    }
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      border-color: ${lighten(0.2, `${cssVar('--colors-text-onBackgroundLight')}`)};

      &:before {
        background-color: ${lighten(0.2, `${cssVar('--colors-text-onBackgroundLight')}`)};
      }
    `}
`;

export {StyledRadio};
