import {
  ChangeEvent,
  ClipboardEvent,
  Fragment,
  HTMLInputAutoCompleteAttribute,
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';
import {IntRange} from 'type-fest';

import {StyledAssistiveText, StyledChar, StyledContainer} from './styled';

export interface PinInputProps {
  name: string;
  length: number;
  hidden?: boolean;
  onlyNumbers?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  type?: 'rounded' | 'square';
  spacing?: IntRange<0, 100>;
  dash?: number | undefined;
  value?: string;
  error?: string | undefined;
  onComplete?: (pin: string) => void;
  onChange?: (args: {originalEvent: ChangeEvent<HTMLInputElement>; value: string}) => void;
  autoFocus?: boolean;
  autoComplete?: HTMLInputAutoCompleteAttribute;
}

const PinInput = ({
  name = '',
  length = 4,
  hidden = true,
  onlyNumbers = true,
  type = 'rounded',
  spacing = 1,
  disabled = false,
  readOnly = false,
  dash = undefined,
  error = undefined,
  onComplete = () => null,
  autoFocus = true,
  autoComplete = '',
  ...props
}: PinInputProps) => {
  const charArray: Array<number> = Array.from({length}, (_, index) => index);
  const defaultValue = props.value ? props.value.split('') : Array.from({length}, () => '');

  const [tokens, setTokens] = useState<string[]>(defaultValue);
  const inputRef = useRef<HTMLInputElement[]>(Array(length).fill(null));

  const findNextInput = (element: Element): HTMLInputElement | null => {
    const nextInput = element.nextElementSibling;

    if (!nextInput) return null;

    if (nextInput.nodeName === 'INPUT') {
      return nextInput as HTMLInputElement;
    } else {
      return findNextInput(nextInput);
    }
  };

  const findPrevInput = (element: Element): HTMLInputElement | null => {
    const prevInput = element.previousElementSibling;

    if (!prevInput) return null;

    if (prevInput.nodeName === 'INPUT') {
      return prevInput as HTMLInputElement;
    } else {
      return findPrevInput(prevInput);
    }
  };

  const moveToNextInput = (event: KeyboardEvent<HTMLInputElement>): void => {
    const nextInput = findNextInput(event.currentTarget as Element);
    if (nextInput) {
      nextInput.focus();
      nextInput.select();
    }
  };

  const moveToPrevInput = (event: KeyboardEvent<HTMLInputElement>): void => {
    const prevInput = findPrevInput(event.currentTarget as Element);
    if (prevInput) {
      prevInput.focus();
      prevInput.select();
    }
  };

  const updateTokens = (value: string, index: number) => {
    const newTokens = [...tokens];
    newTokens[index] = value;
    setTokens(newTokens);
    handleOnChange({target: {value: newTokens.join('')}} as ChangeEvent<HTMLInputElement>, newTokens);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, value: string[]): void => {
    props.onChange?.({
      originalEvent: event,
      value: value.join(''),
    });
  };

  const handleInput = (event: SyntheticEvent<HTMLInputElement>, index: number): void => {
    const nativeEvent = event.nativeEvent as InputEvent;

    if (disabled || readOnly) {
      return;
    }

    if (nativeEvent.inputType === 'insertFromPaste') {
      return; // handled in handlePaste
    }

    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue !== '') {
      inputRef.current[index]?.classList.add('filled');
    }

    updateTokens(inputValue, index);

    if (nativeEvent.inputType === 'deleteContentBackward') {
      const hasNextInput = index < length - 1;

      if (hasNextInput) {
        const newTokens = [...tokens];
        newTokens.splice(index, 1);

        setTokens([...newTokens, '']);
        inputRef.current[length - 1]?.classList.remove('filled');
      }

      moveToPrevInput(event as KeyboardEvent<HTMLInputElement>);
    } else if (nativeEvent.inputType === 'insertText' || nativeEvent.inputType === 'deleteContentForward') {
      moveToNextInput(event as KeyboardEvent<HTMLInputElement>);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    const {key, code} = event;

    switch (code) {
      case 'ArrowLeft':
        moveToPrevInput(event);
        event.preventDefault();
        break;

      case 'ArrowRight':
        moveToNextInput(event);
        event.preventDefault();
        break;

      case 'Backspace':
        if (event.currentTarget.value.length === 0) {
          moveToPrevInput(event);
          event.preventDefault();
        }
        break;

      case 'ArrowUp':
      case 'ArrowDown':
        event.preventDefault();
        break;

      case 'Tab':
      case 'Enter':
        break;

      default:
        if (
          (onlyNumbers && !(code !== 'Space' && Number(key) >= 0 && Number(key) <= 9)) ||
          (tokens.join('').length >= length && code !== 'Delete')
        ) {
          event.preventDefault();
        }
        break;
    }
  };

  const handlePaste = useCallback(
    (event: ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const content = event.clipboardData.getData('text');

      if (content.length) {
        const pastedCode = content.substring(0, length);
        if (!onlyNumbers || !isNaN(parseInt(pastedCode))) {
          const newTokens = pastedCode.split('');
          setTokens(newTokens);
        }
      }
    },
    [length, onlyNumbers],
  );

  useEffect(() => {
    const newPin = tokens.join('');
    if (newPin.length === length) {
      onComplete(newPin);
    }
  }, [tokens, length, onComplete]);

  return (
    <StyledContainer $spacing={spacing} $error={error}>
      {charArray.map((char, index) => (
        <Fragment key={`${name}-${char}`}>
          {index === dash && <Icon name="minus" />}

          <StyledChar
            ref={ref => (inputRef.current[index] = ref as HTMLInputElement)}
            type={hidden ? 'password' : 'text'}
            name={`otp-input-${name}-${char + 1}`}
            value={tokens[index]}
            inputMode={onlyNumbers ? 'numeric' : 'text'}
            aria-label={`One time password character ${char + 1}`}
            $type={type}
            $hidden={hidden}
            className={tokens[index] !== '' ? 'filled' : ''}
            onInput={event => handleInput(event, index)}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            autoFocus={index === 0 && autoFocus}
            autoComplete={autoComplete}
            maxLength={1}
          />
        </Fragment>
      ))}

      {error && <StyledAssistiveText>{error}</StyledAssistiveText>}
    </StyledContainer>
  );
};

export {PinInput};
