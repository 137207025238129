/**
 * Parses the locale string to return the first locale if multiple are present.
 *
 * @param {string} locale - The locale string that may contain multiple locales separated by commas.
 * @returns {string} The primary locale.
 */
function parseLocale(locale: string): string {
  if (locale && locale.includes(',')) {
    return locale.split(',')[0];
  }
  return locale;
}

/**
 * Returns the preferred locale from the user's browser settings.
 *
 * This function retrieves the user's preferred language setting from the browser
 * and returns it. If no setting is found, it defaults to 'en'.
 *
 * @returns {string} The preferred locale.
 *
 * @example
 * const userLocale = getLocale();
 * console.log(userLocale); // e.g., 'en-US' or 'fr'
 */
export const getLocale = (): string => {
  return parseLocale(navigator.language || 'en');
};
