import {DefaultTransition} from 'src/styled/transitions';
import styled from 'styled-components';

interface KastaTagProps {
  $disabled?: boolean;
}

const StyledIconContainer = styled.div`
  display: inline-flex;
  flex: 0 1 32px;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 6px;
  margin-right: 4px;
  background-color: var(--colors-primary-500);
  border-radius: 8px;

  [role='icon'] {
    color: var(--colors-primary-100);
  }
`;

const StyledKastaTag = styled.button<KastaTagProps>`
  position: relative;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 4px 12px 4px 4px;
  cursor: pointer;
  background-color: var(--colors-primary-400);
  border-radius: 12px;

  p {
    flex: 1;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    color: var(--colors-text-onPrimary);
    letter-spacing: 0.1px;
  }

  &,
  ${StyledIconContainer} {
    ${DefaultTransition()};
  }

  &:hover {
    background-color: var(--colors-primary-300);

    ${StyledIconContainer} {
      background-color: var(--colors-primary-400);
    }
  }

  &:focus,
  &:active {
    background-color: var(--colors-primary-200);

    ${StyledIconContainer} {
      background-color: var(--colors-primary-300);
    }
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
    background-color: var(--colors-primary-100);

    ${StyledIconContainer} {
      background-color: var(--colors-primary-200);
    }
  }
`;

export {StyledKastaTag, StyledIconContainer};
