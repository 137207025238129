import defaultImage from '@kasta-io/icons/files/android-icon-192x192.png';
import {QRCodeSVG} from 'qrcode.react';
import {IntRange} from 'type-fest';

import type {Color as ColorType} from '@kasta-io/tokens/src/types';

import {StyledContainer} from './styled';

declare type OverrideImageSettings = {
  src?: string;
  height?: number;
  width?: number;
  excavate?: boolean;
  x?: number;
  y?: number;
};

export interface QRCodeProps {
  link: string;
  size?: number;
  level?: 'L' | 'M' | 'Q' | 'H';
  backgroundColor?: ColorType;
  foregroundColor?: ColorType;
  marginSize?: number | undefined;
  imageSettings?: OverrideImageSettings;
  spacing?: IntRange<0, 100>;
}

const defaultImageSettings = {
  src: typeof defaultImage === 'string' ? defaultImage : '',
  height: 48,
  width: 48,
  excavate: false,
};

const QRCode = ({
  link = '',
  size = 230,
  imageSettings = {},
  backgroundColor = 'var(--colors-background-transparent)',
  foregroundColor = 'var(--colors-primary-500)',
  marginSize = 4,
  level = 'H',
  spacing = 1,
  ...props
}: QRCodeProps) => (
  <StyledContainer $spacing={spacing} {...props}>
    <QRCodeSVG
      value={link}
      level={level}
      imageSettings={{...defaultImageSettings, ...imageSettings}}
      bgColor={backgroundColor}
      fgColor={foregroundColor}
      marginSize={marginSize}
      size={size}
    />
  </StyledContainer>
);

export {QRCode};
