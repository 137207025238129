import styled from 'styled-components';

const StyledExplainer = styled.div`
  position: relative;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;

  h6 {
    flex: 1;
  }

  [role='icon'] {
    flex: 0 1 48px;
  }
`;

export {StyledExplainer, StyledHeader};
