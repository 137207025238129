import dynamic from 'next/dynamic';
import {classNames} from 'primereact/utils';

import {Icon} from '@kasta-io/components';

import {useDialogContext} from '@/contexts/DialogContext';

import type {ICON_COLORS} from '@/components/ui/buttons/MainButton/MainButton';
import type {ReactNode} from 'react';

import s from './DialogBody.module.scss';

const DynamicMainButton = dynamic(() => import('@/components/ui/buttons/MainButton/MainButton'));
const DynamicWarningPill = dynamic(() => import('@/components/ui/WarningPill/WarningPill'));

export interface DialogBodyPropType {
  title?: string;
  image?: ReactNode;
  icon?: string;
  iconColor?: string | keyof typeof ICON_COLORS;
  body?: ReactNode;
  warning?: string;
  closeLabel?: string;
  onClose?: Function;
  closeLabelLeftIcon?: string;
  bodyPlacement?: 'AFTER_CTA' | 'BEFORE_CTA';
  cancelHideDialog?: boolean;
  extraButtons?: ReactNode[];
  buttonsContainerClassName?: string;
  addPadding?: boolean;
}

const DialogBody = ({
  title,
  image,
  icon,
  iconColor = 'var(--colors-main-primary)',
  body,
  warning,
  closeLabel,
  onClose,
  closeLabelLeftIcon,
  cancelHideDialog,
  bodyPlacement = 'AFTER_CTA',
  extraButtons,
  buttonsContainerClassName,
  addPadding,
}: DialogBodyPropType) => {
  const {hideDialog} = useDialogContext();

  const handleClose = async () => {
    !cancelHideDialog && hideDialog();
    onClose && (await onClose());
  };

  const getCallToAction = () => {
    const closeButton = closeLabel ? (
      <DynamicMainButton
        iconFontSize={24}
        leftIcon={closeLabelLeftIcon}
        label={closeLabel}
        size="SMALL"
        fullWidth
        variant="SECONDARY"
        onClick={handleClose}
      />
    ) : null;
    if (extraButtons) {
      // TODO: REFACTOR  extraButtons + close button usage
      // stop wrapping buttons inside a div to style the container, maybe decide if using an array is still a good approach, I am good with it
      //  move this class buttonsContainerClassName style to the component and make it a grid with row spacing
      return (
        <div className={buttonsContainerClassName}>
          {...extraButtons}
          {closeButton}
        </div>
      );
    }
    return closeButton;
  };

  const callToAction = getCallToAction();

  const wrapperClassName = classNames(s.wrapper, {
    [s.padding]: addPadding,
  });

  return (
    <div className={wrapperClassName}>
      {icon && (
        <div className={s.iconWrapper}>
          <Icon name={icon} color={iconColor} size={72} />
        </div>
      )}
      {image && image}
      {title && <p className={s.title}>{title}</p>}
      {warning && <DynamicWarningPill warning={warning} />}
      {bodyPlacement === 'BEFORE_CTA' && body}
      {callToAction}
      {bodyPlacement === 'AFTER_CTA' && body}
    </div>
  );
};

export default DialogBody;
