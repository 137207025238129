import viewports from '@kasta-io/tokens/primitives/viewport.json';
import {Button} from 'src/atoms/Button/Button';
import {DefaultBackdrop} from 'src/styled/mixins';
import {TransitionClasses} from 'src/styled/transitions';
import styled, {css} from 'styled-components';

export interface DialogProps {
  $elevation: boolean;
  $mobileFullScreen: boolean;
  $popOver: boolean;
}

const StyledDialog = styled.div<DialogProps>`
  position: relative;
  width: 90%;
  max-width: 624px;
  max-height: 90%;
  padding: 24px;
  margin: 0 auto;
  overflow-y: auto;
  scrollbar-width: none;
  background-color: var(--colors-text-onPrimary);
  border-radius: 16px;

  ${({$elevation}: DialogProps) =>
    $elevation &&
    css`
      box-shadow: var(--shadow--24dp);
    `}

  ${({$mobileFullScreen}: DialogProps) =>
    $mobileFullScreen &&
    css`
      @media screen and (max-width: ${viewports['tablet']}) {
        width: 100vw;
        height: 100vh;
        border-radius: 0;
      }
    `}

    ${({$popOver}: DialogProps) =>
    $popOver &&
    css`
      @media screen and (max-width: ${viewports['tablet']}) {
        align-self: flex-end;
        width: 100%;
        max-width: 100%;
        padding: 24px 32px;
        border-radius: 16px 16px 0 0;
      }
    `}
`;

const StyledBackdrop = styled.div`
  ${DefaultBackdrop};

  ${TransitionClasses({selector: 'dialog'})}
`;

const StyledDialogHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const StyledCloseButton = styled(Button)`
  align-self: flex-end;
  margin-left: auto;
`;

export {StyledDialog, StyledBackdrop, StyledDialogHeader, StyledCloseButton};
