import Image from 'next/image';

import {Button, Dialog, Typography} from '@kasta-io/components';

import {handleContactSupport} from '@/utils/support';

import s from './KycUnsuccessfulDialog.module.scss';

interface Props {
  isVisible: boolean;
  toggleDialog: () => void;
}

export const KycUnsuccessfulDialog = ({toggleDialog, isVisible}: Props) => {
  const onContactSupport = () => {
    toggleDialog();
    handleContactSupport();
  };

  return (
    <Dialog open={isVisible} popOver onClose={toggleDialog} closeOnBackdropClick hasCloseButton={false}>
      <div className={s.container}>
        <Image src="/images/yellow-column.png" width={80} height={80} alt="" quality={100} />
        <Typography variant="h6" as="h6">
          Verification Unsuccessful
        </Typography>

        <Typography variant="body2" color="var(--colors-text-onBackgroundMedium)">
          We couldn&apos;t complete your verification. For assistance, please contact support to resolve the
          issue.
        </Typography>

        <Button className={s.button} onClick={onContactSupport}>
          Contact support
        </Button>
      </div>
    </Dialog>
  );
};
