import {ptToPx} from 'src/styled/utils';
import styled from 'styled-components';

interface DividerProps {
  $height: number;
  $spacing: number;
  $background: string;
}

const StyledDivider = styled.hr<DividerProps>`
  position: relative;
  display: block;
  width: 100%;
  height: ${({$height}: DividerProps) => `${$height}px`};
  padding: 0;
  margin: ${({$spacing}: DividerProps) => `${ptToPx($spacing)}px 0`};
  background-color: ${({$background}: DividerProps) => $background || 'var(--colors-background-divider)'};
  border: none;
`;

export {StyledDivider};
