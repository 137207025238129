import {DefaultTransition} from 'src/styled/transitions';
import {ptToPx} from 'src/styled/utils';
import styled, {css} from 'styled-components';

interface ContainerProps {
  $spacing: number;
}

interface ListProps {
  $style: 'flat' | 'pill';
}

interface ButtonProps extends ListProps {
  disabled?: boolean | undefined;
  $active: boolean;
}

const StyledTabs = styled.div<ContainerProps>`
  position: relative;
  margin: ${({$spacing}: ContainerProps) => `${ptToPx($spacing)}px 0`};
`;

const StyledNav = styled.ul<ListProps>`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  ${({$style}: ListProps) =>
    $style !== 'flat' &&
    css`
      gap: 8px;
    `}
`;

const StyledNavItem = styled.li`
  flex: 1;
`;

const StyledButton = styled.button<ButtonProps>`
  display: block;
  width: 100%;
  font-family: ${({$style}: ListProps) =>
    $style === 'flat' ? 'var(--font-styles-button1-family)' : 'var(--font-styles-body2-family)'};
  font-size: ${({$style}: ListProps) =>
    $style === 'flat' ? 'var(--font-styles-button1-size)' : 'var(--font-styles-body2-size)'};
  font-style: normal;
  font-weight: ${({$style}: ListProps) =>
    $style === 'flat' ? 'var(--font-styles-button1-weight)' : 'var(--font-styles-body2-weight)'};
  line-height: ${({$style}: ListProps) =>
    $style === 'flat' ? 'var(--font-styles-button1-lineHeight)' : 'var(--font-styles-body2-lineHeight)'};
  letter-spacing: ${({$style}: ListProps) =>
    $style === 'flat' ? 'var(--font-styles-button1-spacing)' : 'var(--font-styles-body2-spacing)'};
  cursor: pointer;
  ${DefaultTransition()};

  ${({$style, disabled, $active}: ButtonProps) =>
    $style === 'flat'
      ? css`
          padding: 8px 0;
          color: ${disabled
            ? 'var(--colors-text-onBackgroundUltraLight)'
            : $active
              ? 'var(--colors-primary-500)'
              : 'var(--colors-text-onBackgroundMedium)'};
          border-color: ${disabled
            ? 'var(--colors-primary-50)'
            : $active
              ? 'var(--colors-primary-500)'
              : 'var(--colors-primary-50)'};
          border-bottom: 1px solid;

          &:hover {
            border-color: var(--colors-primary-200);
          }
        `
      : css`
          padding: 8px 16px;
          color: ${$active ? 'var(--colors-text-onPrimary)' : 'var(--colors-text-onBackgrond)'};
          background-color: ${disabled
            ? 'var(--colors-background-darkGrey)'
            : $active
              ? 'var(--colors-primary-500)'
              : 'var(--colors-background-darkGrey)'};
          border-radius: 8px;
          opacity: ${disabled ? '0.38' : '1'};

          &:hover {
            color: var(--colors-primary-500);
            background-color: var(--colors-primary-50);
          }
        `}

  &:disabled,
  &[disabled] {
    pointer-events: none;
  }
`;

const StyledContent = styled.div`
  position: relative;
`;

export {StyledTabs, StyledNav, StyledNavItem, StyledButton, StyledContent};
