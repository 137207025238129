import {ReactNode} from 'react';

import {StyledBadge} from './styled';

export interface BadgeProps {
  status?: 'primary' | 'neutral' | 'warning' | 'success' | 'destructive';
  type?: 'filled' | 'accent' | 'outlined';
  size?: 'small' | 'medium' | 'large';
  children?: ReactNode;
  as?: keyof JSX.IntrinsicElements;
}

const Badge = ({
  status = 'primary',
  type = 'filled',
  size = 'medium',
  children = null,
  ...props
}: BadgeProps) => (
  <StyledBadge $status={status} $type={type} $size={size} {...props}>
    {children}
  </StyledBadge>
);

export {Badge};
