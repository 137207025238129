import {FormEvent, forwardRef, HTMLAttributes} from 'react';

import {StyledContainer, StyledToggle} from './styled';

export interface ToggleProps extends HTMLAttributes<HTMLInputElement> {
  name: string;
  id?: string;
  disabled?: boolean;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
}

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({name = '', id = '', disabled = false, ...props}, ref) => (
    <StyledContainer>
      <StyledToggle ref={ref} type="checkbox" name={name} id={id} disabled={disabled} {...props} />
      <span aria-hidden="true" />
    </StyledContainer>
  ),
);

export {Toggle};
