import {HTMLAttributes} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';

import {StyledFloatingActionButton} from './styled';

export interface FloatingActionButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label: string;
  icon: string;
  badge?: boolean;
  disabled?: boolean;
  as?: keyof JSX.IntrinsicElements;
}

const FloatingActionButton = ({
  label = '',
  icon = '',
  badge = false,
  disabled = false,
  ...props
}: FloatingActionButtonProps) => (
  <StyledFloatingActionButton $badge={badge} disabled={disabled} {...props} aria-label={label}>
    <Icon name={icon} size={24} color="var(--colors-text-onPrimary)" />

    {label}
  </StyledFloatingActionButton>
);

export {FloatingActionButton};
