import {useCallback, useRef} from 'react';
import OneSignal from 'react-onesignal';

import {useUser} from '@/contexts/UserContext';

const useNotificationCenter = () => {
  const {fbUser, user} = useUser();
  const isInitialized = useRef(false);

  const getUserConsentFromCookieBot = useCallback(() => window?.CookieConsent?.consent?.marketing, []);

  const askPermissionToNotify = () => {
    const consentUser = getUserConsentFromCookieBot();

    if (consentUser && isInitialized.current === false) {
      isInitialized.current = true;

      OneSignal.init({
        appId: `${process.env.NEXT_PUBLIC_ONE_SIGNAL_ACCOUNT_ID}`,
        notifyButton: {
          enable: true,
        },
        // uncomment the line below to enable localhost testing
        // allowLocalhostAsSecureOrigin: true,
      }).then(() => {
        loginUser();
      });
    }
  };

  const loginUser = () => {
    if (fbUser?.uid) {
      OneSignal.login(fbUser.uid);
    }

    if (user?.email) {
      OneSignal.User.addEmail(user.email);
    }
  };

  const logoutUser = () => {
    OneSignal.logout();
  };

  return {
    askPermissionToNotify,
    loginUserOnNotitificationPlataform: loginUser,
    logoutUserOnNotificationPlatform: logoutUser,
  };
};

export default useNotificationCenter;
