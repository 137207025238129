import {HTMLAttributes, ReactNode} from 'react';

import type {Color as ColorType} from '@kasta-io/tokens/src/types';

import {StyledTypography} from './styled';

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'h7'
    | 'button1'
    | 'button2'
    | 'body1'
    | 'body2'
    | 'subtitle1'
    | 'subtitle2'
    | 'subtitle3'
    | 'caption1'
    | 'caption2'
    | 'overline';
  weight?: '400' | 'normal' | 'bold' | undefined;
  color?: ColorType;
  transform?: 'none' | 'lowercase' | 'uppercase' | 'capitalize';
  children?: ReactNode;
  as?: keyof JSX.IntrinsicElements;
  htmlFor?: string;
}

const Typography = ({
  variant = 'body1',
  weight = undefined,
  color = undefined,
  transform = 'none',
  children = null,
  ...props
}: TypographyProps) => (
  <StyledTypography $variant={variant} $weight={weight} $color={color} $transform={transform} {...props}>
    {children}
  </StyledTypography>
);

export {Typography};
