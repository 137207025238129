import {ptToPx} from 'src/styled/utils';
import styled from 'styled-components';

interface ContainerProps {
  $spacing: number;
}

const StyledContainer = styled.div<ContainerProps>`
  margin: ${({$spacing}: ContainerProps) => `${ptToPx($spacing)}px 0`};
`;

export {StyledContainer};
