import {gql} from '@apollo/client';

export const userUpdatedSubscription = gql(/* GraphQL */ `
  subscription publishedUserChanges {
    publishedUserChanges {
      id
      firstName
      lastName
      email
      phoneNumber
      kastaName
      telegramName
      address {
        street
        aptNumber
        city
        postalCode
        country
      }
      referrerCode
      kyc {
        status
        level
        rejectReason
        pending {
          status
          level
          rejectReason
        }
        history {
          status
          level
          rejectReason
          updatedAt
        }
      }
      twoFactorAuth {
        isEnabled
        createdAt
        disabledAt
      }
      deviceToken
      deviceAppInstanceId
      deviceType
      createdAt
      updatedAt
      cashAccountStatus
    }
  }
`);

export const accountUpdatedSubscription = gql(/* GraphQL */ `
  subscription publishedUserAccount {
    publishedUserAccount {
      account {
        balances {
          EUR
          BTC
          KASTA
          ETH
          USDT
          POL
          SOL
          USDC
          BNB
          BUSD
          ADA
          DOT
        }
        currencyBalances {
          currency
          available
          incoming
          reserved
          lockedUp
        }
      }
    }
  }
`);

export const exchangeRatesUpdatedSubscription = gql(/* GraphQL */ `
  subscription publishedExchangeRates {
    publishedExchangeRates {
      rates {
        pair
        value
        value24h
        change24h
      }
    }
  }
`);

export const debitCardUpdatedSubscription = gql(/* GraphQL */ `
  subscription publishedCardUpdates {
    publishedCardUpdates {
      userId
      card {
        id
        displayName
        brand
        type
        status
        expMonth
        expYear
        last4
        feesTransactionIds
      }
    }
  }
`);

export const buyCryptoPublishedConsent = gql(/* GraphQL */ `
  subscription publishedConsent($input: PublishedConsentInput) {
    publishedConsent(input: $input) {
      transactionId
      subscriptionId
      expiresAt
      redirectUrl
    }
  }
`);
