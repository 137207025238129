import {Icon} from '@kasta-io/components';

const Splash = () => (
  <main
    style={{
      height: '100vh',
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <Icon name="kasta-shorticon" size={96} />
  </main>
);

export default Splash;
