import {DefaultBlockPadding, DefaultBlockRadius} from 'src/styled/mixins';
import {pxToRem} from 'src/styled/utils';
import styled from 'styled-components';

import type {ThemeType} from 'src/lib/types';

const types = {
  primary: {
    backgroundColor: 'var(--colors-primary-50)',
    icon: 'var(--colors-primary-500)',
  },
  secondary: {
    backgroundColor: 'var(--colors-success-50)',
    icon: 'var(--colors-text-OnBackground)',
  },
  accent: {
    backgroundColor: 'var(--colors-warning-50)',
    icon: 'var(--colors-text-OnBackground)',
  },
  white: {
    backgroundColor: 'var(--colors-neutral-50)',
    icon: 'var(--colors-text-OnBackground)',
  },
};

const StyledPill = styled.div<{$type: ThemeType; $spacing: number}>`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  ${DefaultBlockPadding};
  margin: ${({$spacing}) => `${pxToRem($spacing * 4)}rem 0`};
  background-color: ${({$type}) => `${types[$type].backgroundColor}`};
  ${DefaultBlockRadius};

  [role='icon'] {
    flex: 0 1 24px;
    margin-right: 8px;
    color: ${({$type}) => `${types[$type].icon}`};
  }

  p {
    flex: 1;
    color: var(--colors-text-onBackgroundMedium);
  }
`;

const StyledCTAContainer = styled.div`
  flex: 1 1 100%;
  text-align: right;
`;

export {StyledPill, StyledCTAContainer};
