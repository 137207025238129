import {useEffect} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';
import {Typography} from 'src/atoms/Typography/Typography';
import {useToast} from 'src/hooks/useToast';
import {TOAST_TIMEOUT} from 'src/lib/constants';

import {StyledToast, StyledToastContent} from './styled';

export interface ToastProps {
  id: string;
  title?: string;
  text: string;
  type?: 'success' | 'info' | 'warning' | 'error' | 'message';
  closeWithTimeout?: boolean;
  timeout?: number;
}

const icons = {
  success: 'check-circle',
  info: 'info',
  warning: 'warning',
  error: 'close-big',
  message: '',
};

const Toast = ({
  id = 'toast-0',
  title = '',
  text = '',
  type = 'info',
  closeWithTimeout = true,
  timeout = TOAST_TIMEOUT,
  ...props
}: ToastProps) => {
  const {removeToast} = useToast();

  useEffect(() => {
    if (closeWithTimeout) {
      const timer = setTimeout(() => {
        removeToast(id);
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [id, removeToast, closeWithTimeout]);

  return (
    <StyledToast $type={type} {...props}>
      {icons[type] !== '' && <Icon name={icons[type]} size={20} />}

      <StyledToastContent>
        {title !== '' && (
          <Typography as="h6" variant="subtitle2">
            {title}
          </Typography>
        )}
        <Typography variant="body2">{text}</Typography>
      </StyledToastContent>
    </StyledToast>
  );
};

export {Toast};
