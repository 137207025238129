import {DefaultInput, DefaultLabel} from 'src/styled/mixins';
import styled, {css} from 'styled-components';

interface LabelProps {
  $floating: boolean;
}

interface InputProps {
  disabled?: boolean | undefined;
  $error?: boolean;
  $bold?: boolean;
  as?: keyof JSX.IntrinsicElements;
  $floatingLabel?: boolean;
}

const StyledLabel = styled.label<LabelProps>`
  ${DefaultLabel}

  ${({$floating}: LabelProps) =>
    $floating &&
    css`
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    `}
`;

const StyledInput = styled.input<InputProps>`
  ${DefaultInput};

  &::placeholder {
    color: var(--colors-text-onBackgroundMedium);
  }

  &:focus,
  &:active {
    border-color: var(--colors-primary-500);

    &,
    & ~ ${StyledLabel} {
      color: var(--colors-primary-500);
    }
  }

  &:focus,
  &:active,
  &.filled {
    & ~ ${StyledLabel} {
      &[data-floating] {
        font-size: var(--font-styles-caption1-size);
        line-height: var(--font-styles-caption1-lineHeight);
        transform: translateY(calc(-100% - 4px));
      }
    }

    &::placeholder {
      color: var(--colors-text-onBackgroundMedium);
    }
  }

  ${props =>
    props.$floatingLabel &&
    css`
      &::placeholder {
        color: transparent;
      }
    `}

  ${props =>
    props.$error &&
    css`
      border-color: var(--colors-danger-500);

      &,
      & ~ ${StyledLabel} {
        color: var(--colors-danger-500);
      }
    `}

  ${props =>
    props.as === 'textarea' &&
    css`
      height: 136px; /* 5 rows */
      resize: none;
    `}
`;

const StyledAssistiveText = styled.small`
  padding-left: 16px;
  font-size: var(--font-styles-caption1-size);
  line-height: var(--font-styles-caption1-lineHeight);
  color: var(--colors-danger-500);
  letter-spacing: var(--font-styles-caption1-spacing);
`;

export {StyledLabel, StyledInput, StyledAssistiveText};
