import {DefaultBlockPadding, DefaultBlockRadius} from 'src/styled/mixins';
import styled, {css} from 'styled-components';

interface CardProps {
  $radius: number;
  $backgroundColor: string;
  $shadow: string;
}

const StyledCard = styled.div<CardProps>`
  position: relative;
  display: block;
  ${DefaultBlockPadding};
  background-color: ${({$backgroundColor}: CardProps) => $backgroundColor};

  ${({$radius}: CardProps) =>
    $radius
      ? css`
          border-radius: ${$radius}px;
        `
      : DefaultBlockRadius};

  ${({$shadow}: CardProps) =>
    $shadow !== '' &&
    css`
      box-shadow: ${$shadow};
    `};
`;

export {StyledCard};
