import {keyframes} from 'styled-components';

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const spinnerColors = keyframes`
  0%,
  100% {
    stroke: var(--colors-primary-500);
  }

  40% {
    stroke: var(--colors-success-500);
  }

  66% {
    stroke: var(--colors-warning-600);
  }

  80%,
  90% {
    stroke: var(--colors-danger-500);
  }
`;

export const spinnerDash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

export const growAndHide = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(2);
  }
`;

export const skeletonWave = keyframes`
  to {
    transform: translateX(100%);
  }
`;

export const skeletonPulse = keyframes`
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
`;
