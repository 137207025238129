import {HTMLAttributes, ReactNode} from 'react';

import type {Color as ColorType, Shadow as ShadowType} from '@kasta-io/tokens/src/types';

import {StyledCard} from './styled';

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  radius?: number;
  backgroundColor?: ColorType;
  shadow?: ShadowType;
  children?: ReactNode;
}

const Card = ({
  radius = 12,
  backgroundColor = 'var(--colors-background-paper)',
  shadow = 'none',
  children = null,
  ...props
}: CardProps) => (
  <StyledCard $radius={radius} $backgroundColor={backgroundColor} $shadow={shadow} {...props}>
    <>{children}</>
  </StyledCard>
);

export {Card};
