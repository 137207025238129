import {MouseEvent as ReactMouseEvent, ReactNode, useRef} from 'react';
import {createPortal} from 'react-dom';
import {CSSTransition} from 'react-transition-group';

import {type ButtonProps} from 'src/atoms/Button/Button';
import {Icon} from 'src/atoms/Icon/Icon';
import {POPUP_ANIMATION_TIMEOUT, POPUP_PORTAL_NODE} from 'src/lib/constants';

import {StyledBackdrop, StyledCloseButton, StyledContent, StyledPopUp} from './styled';

export interface PopUpProps {
  open?: boolean;
  icon?: string;
  children?: ReactNode;
  hasCloseButton?: boolean;
  onClose?: (e: ReactMouseEvent) => void;
  closeButtonType?: ButtonProps['type'];
  mobileFullScreen?: boolean;
  popOver?: boolean;
  selector?: string;
}

const Component = ({
  icon = '',
  hasCloseButton = false,
  closeButtonType = 'primary-icon',
  mobileFullScreen = false,
  popOver = false,
  children = null,
  open = false,
  onClose = () => null,
  ...props
}: PopUpProps) => (
  <CSSTransition in={open} timeout={POPUP_ANIMATION_TIMEOUT} classNames="popup" unmountOnExit appear enter>
    <StyledBackdrop>
      <StyledPopUp $mobileFullScreen={mobileFullScreen} $popOver={popOver} {...props}>
        {hasCloseButton && (
          <StyledCloseButton size="xs" onClick={onClose} aria-label="Close popup" type={closeButtonType}>
            <Icon name="close-big" />
          </StyledCloseButton>
        )}
        {icon !== '' && <Icon name={icon} size={72} color="var(--colors-primary-500)" />}

        <StyledContent>{children}</StyledContent>
      </StyledPopUp>
    </StyledBackdrop>
  </CSSTransition>
);

const PopUp = ({
  open = false,
  onClose = () => null,
  children,
  selector = `#${POPUP_PORTAL_NODE}`,
  ...props
}: PopUpProps) => {
  const ref = useRef<HTMLElement | DocumentFragment>(document.querySelector(selector) as HTMLElement);

  return (
    <>
      {open
        ? createPortal(
            <Component onClose={onClose} open={open} {...props}>
              {children}
            </Component>,
            ref.current,
          )
        : null}
    </>
  );
};

export {PopUp};
