import viewports from '@kasta-io/tokens/primitives/viewport.json';
import {Dialog} from 'src/molecules/Dialog/Dialog';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  position: relative;

  @media screen and (max-width: ${viewports['tablet']}) {
    align-self: flex-end;
    width: 100%;
    max-width: 100%;
    border-radius: 16px 16px 0 0;
  }
`;

const StyledContent = styled.div`
  position: relative;
`;

export {StyledDialog, StyledContent};
