import {ToastMessageOptions} from 'primereact/toast';

import {CashAccountStatus, Currency, IntergiroScaType} from '@/graphql/__generated__/graphql';
import {KycStatus} from '@/types/compliance';
import {DebitCardStatus} from '@/types/debitCard';

import {isDev} from '@/utils/device';

export const SPLASH_SCREEN_PATH = '/';
export const BREAKPOINTS = {
  mobile: 320,
  smartphone: 480,
  'tablet-s': 600,
  tablet: 768,
  'desktop-s': 900,
  desktop: 1024,
  screen: 1200,
};

export const UNBIND_2FA_LABEL = 'Unbind Two-Factor Authentication';
export const ACTIVATE_2FA_LABEL = 'Activate Two-Factor Authentication';

export const SEND_MIN_FIAT_AMOUNT = 0.01;
export const TOP_UP_MIN_AMOUNT = 10;
export const TOP_UP_MAX_AMOUNT = 5000;
export const USER_PIN_LENGTH = 6;
export const FIAT_AMOUNT_DECIMALS = 2;
export const PERCENTAGE_DECIMALS = 2;
export const EXTRA_INTL_CURRENCY_SYMBOLS: {[key in Currency]?: string} = {
  BTC: '₿',
  ETH: 'Ξ',
  USDT: '₮',
};

// regExp
export const ONLY_POSITIVE_INTS_GT_ZERO = /^([1-9][0-9]+|[1-9])$/;
export const ONLY_NUMERIC_REGEXP = /^\d+$/;
export const ONLY_ZEROS_AND_DOT_REGEXP = /^0*\.?0*$/;
export const PHONE_NUMBER_DECORATORS = /[ ()\-+]/g;
export const LEADING_ZEROS = /^0+(?!$)/;
export const TRAILING_AFTER_DECIMAL_ZEROS = /(.[0-9]*[1-9])0+$/g;
export const VALID_EMAIL_ADDRESS = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const INTERGIRO_TEXT_FIELDS_REGEXP = /^[a-zA-Z0-9 ?:()/.,+-]*$/;

// times
export const ONE_SECOND = 1000; // one second in ms
export const THREE_SECOND = 3000; // three seconds in ms
export const TEN_SECONDS = 10_000; // ten seconds in ms
export const ONE_MINUTE = 60_000; // one minute in ms
export const ONE_DAY = 24 * 60 * 60 * 1000; // one day in ms
export const DEFAULT_ANIMATION_DURATION_MS = 300;
export const DEFAULT_ANIMATION_DURATION_S = 0.3;

//==== Business action trigger times
export const RESEND_SMS_MINUTES_WAIT_TIME = 1;
export const RESEND_EMAIL_MINUTES_WAIT_TIME = 2;
export const INACTIVITY_LIMIT_TIMEOUT = 900_000; // 15 minutes in ms
export const SECONDS_START_INACTIVITY_MODAL = 60;

// External links
export const WEBPAGE_URL = 'https://ka.app';
export const FEEDBACK_FORM_URL = 'https://usabi.li/do/64f4b24d8df/c508';
export const TWITTER_URL = 'https://twitter.com/ka_app';
export const TELEGRAM_URL = 'https://t.me/ka_app';
export const YOUTUBE_URL = 'https://www.youtube.com/@ka_app';
export const INSTAGRAM_URL = 'https://www.instagram.com/ka_app/';
export const CASH_TERMS_CONDITIONS = 'https://intergiro.com/baas-terms-and-conditions';
export const CASH_PRIVACY_POLICY = 'https://intergiro.com/baas-privacy-policy';
export const CASH_CARD_CONDITIONS = 'https://www.intergiro.com/acquiring-terms-and-conditions';

export const FIREBASE_COOKIE_KEY = 'firebase-token';
export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

export const environments = {
  PRODUCTION: 'PRODUCTION',
  STAGING: 'STAGING',
};

export const CRYPTO_FEE_TYPE = {
  NETWORK: 'NETWORK',
  BASE: 'BASE',
  SERVICE: 'SERVICE',
  FULL: 'FULL',
} as const;

export const CASH_FEE_TYPE = {
  SERVICE: 'SERVICE',
  COST_OF_SERVICE: 'COST_OF_SERVICE',
  FULL: 'FULL',
  DELIVERY: 'DELIVERY',
} as const;

export const TRANSFORMED_FEE_TYPE = {
  NETWORK: 'NETWORK',
  SERVICE: 'SERVICE',
  FULL: 'FULL',
  DELIVERY: 'DELIVERY',
} as const;

export const FEES_DECIMALS_LENGTH = 7;

export const SELECTOR_OPTIONS_AMOUNTS = [
  {
    label: '+10',
    value: 10,
  },
  {
    label: '+25',
    value: 25,
  },
  {
    label: '+50',
    value: 50,
  },
  {
    label: '+100',
    value: 100,
  },
  {
    label: 'MAX',
    value: 1,
  },
];

export const SELECTOR_OPTIONS_PERCENTAGE = [
  {
    label: '50%',
    value: 0.5,
  },
  {
    label: 'MAX',
    value: 1,
  },
];

export const TIME_FRAME_OPTIONS = {
  day: {
    label: '1D',
    value: 'THIRTY_MINUTES', // returns 48 items
    timeFrame: 'day',
    dateFormat: 'MMMM DD, YYYY',
  },
  week: {
    label: '1W',
    value: 'SIX_HOURS', // returns 28 items
    timeFrame: 'week',
    dateFormat: 'MMMM DD, YYYY',
  },
  month: {
    label: '1M',
    value: 'DAILY', // returns 30 items
    timeFrame: 'month',
    dateFormat: 'MMMM DD, YYYY',
  },
  year: {
    label: '1Y',
    value: 'WEEKLY', // returns up to 52 items
    timeFrame: 'year',
    dateFormat: 'MMMM DD, YYYY',
  },
  all: {
    label: 'All',
    value: 'WEEKLY',
    timeFrame: 'year', // returns up to 52 items
    dateFormat: 'MMMM DD, YYYY',
  },
};

export const RECIPIENT_SOURCE = {
  recents: 'Recent',
  contacts: 'Contacts',
  QRCode: 'QRCode',
  added: 'Added',
} as const;

export const transactionStatusDetails = {
  CREATED:
    'Your transaction is processing. It’ll be completed shortly-thanks for your patience!',
  SETTLED: '',
  FAILED: '',
  LOCKED:
    'The reason why this transaction is locked: You sent an amount over your KYC level limit. Please contact support.',
  CANCELLED:
    'The reason why this transaction was cancelled: User did not fullfil all requirements to complete the flow',
};

export const analyticsEventCurrencyType = {
  swapFrom: 'Convert from currency',
  swapTo: 'Convert to currency',
  deposit: 'Deposit currency',
  send: 'Send currency',
  withdraw: 'Withdraw currency',
  destCurrency: 'dest_currency',
  sourceCurrency: 'source_currency',
  displayCurrency: 'display_currency',
};

export const FALLBACK_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN88+R/PQAI4wNQFbuN5QAAAABJRU5ErkJggg==';

export const ScaStatus = {
  Rejected: 'rejected',
  Approved: 'approved',
  InvalidLink: 'invalid_link',
} as const;

export const ScaScope = IntergiroScaType;

export {CashAccountStatus};

export const SCA_BALANCE_DESCRIPTION =
  "'Your Balance' is hidden as you have not completed your SCA. This is a one-time submission.";
export const SCA_HISTORY_DESCRIPTION =
  "To see 'Cash History', we require you to complete our SCA every 90 days.";

/***************/

/*
 * 3rd Party On/Off-Ramp Providers
 * On other words, third party providers to allow users to buy/sell crypto with fiat.
 * We must integrate with their widgets by using the correct symbols for each currency.
 *
 * API will not provide the correct symbols, since it's 3rd-party provider data
 * and it won't change often.
 */

/***************/

/*
 * 3rd Party Provider: RAMP NETWORK
 * Most of our ROW users see this provider.
 * For Ramp Network, use the "chain" and "symbol" keys to make a "chain_symbol".
 * By declaring a currency with no value, it will be visible in the Asset List as Coming Soon.
 */

// Staging
// https://api.demo.ramp.network/api/host-api/v3/assets
export const RAMP_DEMO_SYMBOL = {
  KASTA: '',
  ADA: 'CARDANO_ADA',
  BTC: 'BTC_BTC',
  ETH: 'GOERLI_ETH',
  USDC: 'GOERLI_USDC',
  POL: 'POL_TEST',
  SOL: 'SOLANA_SOL',
  DOT: 'POLKADOT_DOT',
  DOGE: 'DOGE_DOGE',
  AVAX: 'AVAX_AVAX',
  NEAR: 'NEAR_NEAR',
};

// Production
// https://docs.ramp.network/assets
export const RAMP_PROD_SYMBOL = {
  KASTA: '',
  BNB: 'BSC_BNB',
  BUSD: 'BSC_BUSD',
  BTC: 'BTC_BTC',
  ADA: 'CARDANO_ADA',
  ETH: 'ETH_ETH',
  USDC: 'ETH_USDC',
  POL: 'MATIC_POL',
  SOL: 'SOLANA_SOL',
  DOT: 'POLKADOT_DOT',
  LINK: 'ETH_LINK',
  DOGE: 'DOGE_DOGE',
  AVAX: 'AVAX_AVAX',
  // TON: 'TON_TON', // Only supported for Buy, not Sell
  // TRX: 'TRON_TRX', // Only supported for Buy, not Sell
  // NEAR: 'NEAR_NEAR', // Only supported for Buy, not Sell
};

/*
 * 3rd Party Provider: ONRAMP MONEY
 * Only 3 countries see this provider. This is because onRamp Money charges smaller fees there.
 * By declaring a currency with no value, it will be visible in the Asset List as Coming Soon.
 */

// Staging
// Only USDT available in staging
export const ONRAMP_DEMO_SYMBOL = {
  KASTA: '',
  USDT: 'usdt',
};

// Production
// https://api.onramp.money/onramp/api/v2/sell/public/allConfig
export const ONRAMP_PROD_SYMBOL = {
  KASTA: '',
  // TODO: Enable once onramp.money correctly configures our app on their end.
  // BNB: 'bnb',
  // BTC: 'btc',
  // ADA: 'ada',
  ETH: 'eth',
  USDC: 'usdc',
  // POL: 'pol',
  // SOL: 'sol',
  // DOT: 'dot',
  // BUSD: 'busd',
  // SOL: 'sol',
  // SHIB: 'shib',
  // LINK: 'link',
  // DOGE: 'doge'
  // AVAX: 'avax',
  // TRX: 'trx',
  // NEAR: 'near'
};

/***************/

export const graphQLErrorTypeToMessages: {
  [key: string]: {
    apiErrorMessage: string;
    displayMessage: {summary: ToastMessageOptions['summary']; detail: ToastMessageOptions['detail']};
    notification?: boolean;
  };
} = {
  UNEXPECTED_ERROR: {
    apiErrorMessage: '',
    displayMessage: {summary: 'Unexpected Error', detail: 'Something went wrong, please try again'},
    notification: isDev,
  },
  ITEM_NOT_FOUND_ERROR: {
    apiErrorMessage: 'Unable to find item using',
    displayMessage: {
      summary: 'Item not found',
      detail: 'Unable to find item, please try again or contact support',
    },
    notification: isDev,
  },
  FIREWALL_ERROR: {
    apiErrorMessage: 'WAFForbiddenException',
    displayMessage: {summary: 'Account locked', detail: 'Wait a few minutes and then try again'},
    notification: isDev,
  },
  SCHEMA_ERROR: {
    apiErrorMessage: 'Validation error of type FieldUndefined',
    displayMessage: {summary: '', detail: 'Something went wrong, please try again'},
    notification: isDev,
  },
  UNAUTHENTICATED_ERROR: {
    apiErrorMessage: 'Not Authorized',
    displayMessage: {summary: 'Not Authorized', detail: 'Session expired, please reload window'},
    notification: isDev,
  },
  USER_ALREADY_EXISTS_ERROR: {
    apiErrorMessage: 'already exists.',
    displayMessage: {
      summary: 'Sign up',
      detail: 'Please sign out before trying to sign up a new account',
    },
    notification: true,
  },
  ACTIVATED_DEBIT_CARD_ERROR: {
    apiErrorMessage: 'This card cannot be activated',
    displayMessage: {summary: 'Debit card activation', detail: 'This card was already activated'},
    notification: true,
  },
  INVALID_DEBIT_CARD_ERROR: {
    apiErrorMessage: '"arguments.input.cardId" must be a valid GUID',
    displayMessage: {summary: 'Debit card', detail: 'Invalid card id provided, please contact support'},
    notification: true,
  },
  USER_ALREADY_HAS_CARD: {
    apiErrorMessage: 'User already has an active or pending activation physical card',
    displayMessage: {detail: '', summary: ''},
    notification: false,
  },
  FREEZE_DEBIT_CARD_ERROR: {
    apiErrorMessage:
      'Card is in the frozen status, you can set only (not_activated, blocked, lost, stolen, fraud) for this card.',
    displayMessage: {
      summary: 'Debit card',
      detail: 'Unable to freeze the debit card, please reach out to customer support.',
    },
    notification: true,
  },
  ALREADY_FROZEN_DEBIT_CARD_ERROR: {
    apiErrorMessage: 'Card is already frozen',
    displayMessage: {summary: 'Debit card', detail: 'This card is already frozen'},
    notification: true,
  },
  INVALID_DEBIT_CARD_PIN_RESET_ERROR: {
    apiErrorMessage: 'PIN reset can be performed only on activated cards',
    displayMessage: {
      summary: 'Debit card',
      detail: 'PIN reset code can be performed only on activated cards',
    },
    notification: true,
  },
  INVALID_DEBIT_CARD_TERMINATE_ERROR: {
    apiErrorMessage: "Variable 'status' has an invalid value.",
    displayMessage: {
      summary: 'Debit card',
      detail: 'Terminate card action cannot be performed',
    },
    notification: true,
  },
  DETAILS_DEBIT_CARD_ERROR: {
    apiErrorMessage: 'Card not active',
    displayMessage: {
      summary: 'Debit card',
      detail: 'Unable to view debit card details. Please reach out to customer support.',
    },
    notification: true,
  },
  EXISTING_PAYMENT_METHOD_ERROR: {
    apiErrorMessage: 'You have reached saved payment methods limit',
    displayMessage: {
      summary: 'Card Top Up',
      detail: 'Unable to add new payment methods. Please delete the current payment method and try again.',
    },
    notification: true,
  },
};

export const KYC_STATUS_AVAILABLE_VERIFICATION = [
  KycStatus.Created,
  KycStatus.RedRetry,
  KycStatus.Reset,
  KycStatus.OnHold,
];

export enum KycLevel {
  One = 'ONE', // Old legacy status for user EEA
  ROW0 = 'ROW#level-0',
  ROW1 = 'ROW#level-1',
  ROW2 = 'ROW#level-2',
  EEA0 = 'EEA#level-0',
}

export const KYC_STATUS_VERIFICATION_IN_PROCESS = [KycStatus.Pending];

export const LEGACY_APP_USERS_KYC_STATUS = 'ONE';
export const DIALOG_TRIGGER_TRANSACTION_KEY = 'trigger_dialog';
export const DEPOSIT_LOCKED_WARNING_TRIGGER = 'deposit_locked_trigger_dialog';
export const CASH_WITHDRAWAL_ADDRESS_STORAGE = 'cash_withdrawal_address';
export const EMAIL_CREDENTIAL_LINKING_STORAGE = 'should_link_email_credential';

// Make smaller list to allow team members to test features in staging
export const FALLBACK_RESTRICTED_COUNTRIES_DEV_ENV = ['US', 'AF', 'PH', 'SG', 'ZA', 'TR'];

export const FALLBACK_RESTRICTED_COUNTRIES = [
  'CA',
  'MA',
  'NG',
  'PH',
  'SG',
  'ZA',
  'TR',
  'AE',
  'GB',
  'AF',
  'DZ',
  'AS',
  'BD',
  'BB',
  'BY',
  'BO',
  'BF',
  'MM',
  'KH',
  'CN',
  'CD',
  'EG',
  'TF',
  'GA',
  'GI',
  'GU',
  'HT',
  'IR',
  'IQ',
  'JM',
  'LB',
  'LR',
  'LY',
  'MO',
  'MW',
  'ML',
  'MR',
  'MZ',
  'MM',
  'NI',
  'KP',
  'PS',
  'QA',
  'RU',
  'ST',
  'SN',
  'SL',
  'SS',
  'SY',
  'TZ',
  'TN',
  'US',
  'UM',
  'VI',
  'YE',
];

export const ORIGIN = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

export const tiersExplainerViewed = 'tiers_explainer_viewed';
export const cashExplainerViewed = 'cash_explainer_viewed';
export const cashTermsAccepted = 'cash_terms_accepted';
export const debitCardTermsAccepted = 'debit_card_terms_accepted';
export const debitCardExplainerViewed = 'debit_card_explainer_viewed';
export const kycWidgetViewed = 'kyc_widget_viewed';
export const lastCurrencySent = 'last_currency_sent';
export const sendFailedErrorMessage = 'send_failed_error_message';
export const convertFailedErrorMessage = 'convert_failed_error_message';
export const lastServiceWorkerUpdateCheck = 'last_service_worker_update_check';

export const GENERIC_WITHDRAWAL_ERROR =
  'Something went wrong trying to calculate your withdrawal fees. Please try again or contact support.';

export const GENERIC_TOP_UP_ERROR =
  'Something went wrong with your top up. Please try again or contact support.';

/**
 * These will be error messages returned by the API that can be shown to the user, by the time the API
 * treat more error message we can safely add more errors to this list
 */
export const EXPECTED_WITHDRAWAL_ERROR = [
  'Only SEPA IBANs are allowed',
  'Beneficiary Account Code is invalid',
];

export const CARD_TERMINATED_STATUS = [
  DebitCardStatus.Stolen,
  DebitCardStatus.Blocked,
  DebitCardStatus.Lost,
  DebitCardStatus.Expired,
  DebitCardStatus.Fraud,
  DebitCardStatus.SecurityBlock,
  DebitCardStatus.Closed,
];

export const SETTINGS_TAB = {
  GENERAL: 'General',
  CASH: 'Cash Settings',
} as const;

export const FEES_AND_LIMITS_TAB = {
  CASH: 'Cash',
  CARD: 'Card',
} as const;

/**
 * As of April 2024, this value refers to the Netherlands.
 */
export const CALLING_CODE_FOR_COUNTRY_WITH_GREATEST_PERCENTAGE_OF_KASTA_USERS = '+31';
export const COUNTRY_WITH_GREATEST_PERCENTAGE_OF_KASTA_USERS = 'Netherlands';

export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export const ONE_HOUR_IN_SEC = 60 * 60;

export const USER_LOCATION = 'user_location';
export const USER_IP = 'user_ip';

// https://docs.datadoghq.com/logs/log_configuration/processors/?tab=ui#log-status-remapper
// In order of most to least critial
export const ERROR_LEVEL = {
  EMERGENCY: 'emergency', // System is unusable
  ALERT: 'alert', // Action must be taken immediately
  CRITICAL: 'critical',
  ERROR: 'error',
  WARNING: 'warning',
  NOTICE: 'notice', // Normal but significant conditions
  INFORMATIONAL: 'informational',
  DEBUG: 'debug',
} as const;

export const TOP_UP_FEE = 1.5;
