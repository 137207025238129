import {useContext} from 'react';

import {ToastContext} from 'src/contexts/toast';

const useToast = () => {
  const toastHelpers = useContext(ToastContext);
  return toastHelpers;
};

export {useToast};
