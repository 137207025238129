import {HTMLAttributes, ReactNode} from 'react';

import {IntRange} from 'type-fest';

import {StyledList, StyledListItem} from './styled';

export interface ListItemProps extends HTMLAttributes<HTMLLIElement> {
  id: string;
  children: string | ReactNode;
}

export interface ListProps extends HTMLAttributes<HTMLUListElement> {
  items: Array<ListItemProps>;
  gap?: IntRange<0, 100>;
  spacing?: IntRange<0, 100>;
}

const List = ({items = [], gap = 1, spacing = 1, ...props}: ListProps) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <StyledList $gap={gap} $spacing={spacing} {...props}>
      {items.map(({id, children, ...props}: ListItemProps) => (
        <StyledListItem key={id} {...props}>
          {children}
        </StyledListItem>
      ))}
    </StyledList>
  );
};

export {List};
