import {HTMLAttributes} from 'react';

import kastaLogo from '@kasta-io/icons/files/custom/kasta-shorticon.svg';

import {StyledSplashScreen} from './styled';

export interface SplashScreenProps extends HTMLAttributes<HTMLDivElement> {
  logo?: string;
  animation?: boolean;
  backgroundColor?: string;
}

const SplashScreen = ({
  logo = kastaLogo,
  animation = true,
  backgroundColor = 'var(--colors-background-default)',
  ...props
}: SplashScreenProps) => (
  <StyledSplashScreen $hasAnimation={animation} $backgroundColor={backgroundColor} {...props}>
    <img src={typeof logo !== 'string' ? '' : logo} alt="Kasta" />
  </StyledSplashScreen>
);

export {SplashScreen};
