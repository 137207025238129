import {ReactNode, useEffect} from 'react';

import {Typography} from 'src/atoms/Typography/Typography';
import {useAlert} from 'src/hooks/useAlert';
import {ALERT_TIMEOUT} from 'src/lib/constants';

import {StyledAlert, StyledCTAContainer} from './styled';

export interface AlertProps {
  id: string;
  text: string;
  cta?: ReactNode;
  timeout?: number;
  closeWithTimeout?: boolean;
}

const Alert = ({
  id = 'alert-0',
  text = '',
  cta = null,
  timeout = ALERT_TIMEOUT,
  closeWithTimeout = true,
  ...props
}: AlertProps) => {
  const {removeAlert} = useAlert();

  useEffect(() => {
    if (closeWithTimeout) {
      const timer = setTimeout(() => {
        removeAlert(id);
      }, timeout);

      return () => {
        clearInterval(timer);
      };
    }
  }, [id, timeout, closeWithTimeout, removeAlert]);

  return (
    <StyledAlert id={id} {...props}>
      <Typography variant="subtitle2" color="var(--colors-text-onPrimary)">
        {text}
      </Typography>

      {cta !== null && <StyledCTAContainer>{cta}</StyledCTAContainer>}
    </StyledAlert>
  );
};

export {Alert};
