import {MouseEvent as ReactMouseEvent, ReactNode} from 'react';

import {Typography} from 'src/atoms/Typography/Typography';

import type {DialogProps} from 'src/molecules/Dialog/Dialog';

import {StyledContent, StyledDialog} from './styled';

export interface TransactionDialogProps extends DialogProps {
  title: string;
  onClose?: (e: ReactMouseEvent) => void;
  children?: ReactNode;
}

const TransactionDialog = ({
  title = '',
  onClose = () => null,
  children = null,
  ...props
}: TransactionDialogProps) => (
  <StyledDialog
    title={
      <Typography as="h6" variant="h7" color="var(--colors-text-onBackgroundMedium)">
        {title}
      </Typography>
    }
    elevation={true}
    onClose={onClose}
    closeButtonType="secondary-icon"
    mobileFullScreen={true}
    {...props}>
    <StyledContent>{children}</StyledContent>
  </StyledDialog>
);

export {TransactionDialog};
