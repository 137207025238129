import {StaticImageData} from 'next/image';

import {DefaultBlockRadius} from 'src/styled/mixins';
import styled from 'styled-components';

import type {ThemeType} from 'src/lib/types';

const types = {
  primary: {
    backgroundColor: 'var(--colors-primary-200)',
    color: 'var(--colors-text-onPrimary)',
  },
  secondary: {
    backgroundColor: 'var(--colors-success-200)',
    color: 'var(--colors-text-onBackgroundMedium)',
  },
  accent: {
    backgroundColor: 'var(--colors-warning-100)',
    color: 'var(--colors-text-onBackgroundMedium)',
  },
  white: {
    backgroundColor: 'var(--colors-background-paper)',
    color: 'var(--colors-text-onBackgroundMedium)',
  },
};

const ContainerButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
`;

const StyledContainer = styled.div<{$type: ThemeType}>`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  min-height: 150px;
  background-color: ${({$type}) => types[$type].backgroundColor};
  ${DefaultBlockRadius};

  h6,
  p,
  [role='icon'] {
    color: ${({$type}) => types[$type].color};
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  width: 200px;
  padding: 20px 20px 20px 16px;
`;

const StyledContentImage = styled.div<{$imageUrl: string | StaticImageData}>`
  position: relative;
  background-image: ${({$imageUrl}) => `url(${typeof $imageUrl === 'string' ? $imageUrl : $imageUrl.src})`};
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
`;

export {ContainerButton, StyledContainer, StyledContent, StyledContentImage};
