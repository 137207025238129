import {MouseEvent as ReactMouseEvent} from 'react';

import {Typography} from 'src/atoms/Typography/Typography';

import {StyledAvatar} from './styled';

export interface AvatarProps {
  username: string;
  image?: string;
  size?: 'small' | 'medium' | 'large';
  as?: keyof JSX.IntrinsicElements;
  onClick?: (e: ReactMouseEvent) => void;
}

const getInitials = (username: string): string => {
  if (!username || username === '') {
    return '';
  }

  return username.substring(0, 1);
};

const Avatar = ({username = '', image = '', size = 'medium', ...props}: AvatarProps) => (
  <StyledAvatar $size={size} $hasImage={image !== ''} $clickable={Boolean(props?.onClick)} {...props}>
    <Typography variant="body1">{getInitials(username)}</Typography>

    {image !== '' && <img src={image} alt={username} />}
  </StyledAvatar>
);

export {Avatar};
