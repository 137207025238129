import viewports from '@kasta-io/tokens/primitives/viewport.json';
import {Button} from 'src/atoms/Button/Button';
import {DefaultBackdrop} from 'src/styled/mixins';
import {TransitionClasses} from 'src/styled/transitions';
import styled, {css} from 'styled-components';

export interface PopUpProps {
  $mobileFullScreen: boolean;
  $popOver: boolean;
}

const StyledPopUp = styled.div<PopUpProps>`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 328px;
  max-width: 90%;
  padding: 24px;
  background-color: var(--colors-background-paper);
  border-radius: 12px;
  box-shadow: var(--shadow--24dp);

  & > [role='icon'] {
    margin-bottom: 24px;
  }

  @media screen and (min-width: ${viewports['tablet']}) {
    width: 554px;
    padding: 48px;
  }

  ${({$mobileFullScreen}: PopUpProps) =>
    $mobileFullScreen &&
    css`
      @media screen and (max-width: ${viewports['tablet']}) {
        width: 100vw;
        height: 100vh;
        border-radius: 0;
      }
    `}

  ${({$popOver}: PopUpProps) =>
    $popOver &&
    css`
      @media screen and (max-width: ${viewports['tablet']}) {
        align-self: flex-end;
        width: 100%;
        max-width: 100%;
        padding: 24px 32px;
        border-radius: 16px 16px 0 0;
      }
    `}
`;

const StyledCloseButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  align-self: flex-end;
  margin-left: auto;

  @media screen and (min-width: ${viewports['tablet']}) {
    top: 16px;
    right: 16px;
  }
`;

const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin-bottom: 4px;
  }

  @media screen and (min-width: ${viewports['tablet']}) {
    p:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const StyledBackdrop = styled.div`
  ${DefaultBackdrop};

  ${TransitionClasses({selector: 'popup'})}
`;

export {StyledPopUp, StyledCloseButton, StyledContent, StyledBackdrop};
