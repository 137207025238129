import {createContext, useCallback, useContext, useEffect, useState} from 'react';

import {Currency} from '@/graphql/__generated__/graphql';
import {exchangeRatesUpdatedSubscription} from '@/graphql/subscriptions';
import {Maybe} from 'yup';

import {afterGet} from '@/controllers/exchangeRates';
import {getImprovedExchangeRates} from '@/graphql/queries';

import {useToastNotificationContext} from '@/contexts/ToastNotificationContext';
import {useSubscribeToEntity} from '@/hooks/useSubscribeToEntity';

import type {
  ExchangeRate,
  ExchangeRates,
  GetImprovedExchangeRatesQuery,
  PublishedExchangeRatesSubscription,
} from '@/graphql/__generated__/graphql';
import type {EmptyObject} from '@/types/generics';
import type {ReactNode} from 'react';

import {useUser} from './UserContext';

type ExchangeRatesType = {
  exchangeRates: OldExchangeRates | EmptyObject;
  exchangeRatesLoading: boolean;
  exchangeRatesError: string;
  exchangeRatesTimestamp: number;
  refetchExchangeRates: Function;
  isEmptyExchangeRates: boolean;
  assetsChange24h: Array<any>;
  variables?: Record<string, any>;
};

export type OldExchangeRates = {
  [key: string]: number | null;
};

const ExchangeRates = createContext<ExchangeRatesType>({
  exchangeRates: {},
  exchangeRatesLoading: false,
  exchangeRatesError: '',
  exchangeRatesTimestamp: 0,
  refetchExchangeRates: () => null,
  isEmptyExchangeRates: true,
  assetsChange24h: [],
});

export const useExchangeRates = () => useContext(ExchangeRates);

const ExchangeRatesProvider = ({children}: {children: ReactNode}) => {
  const {user} = useUser();
  const {setToastNotification} = useToastNotificationContext();
  const [latestExchangeRates, setLatestExchangeRates] = useState<EmptyObject | OldExchangeRates>({});
  const [exchangeRatesTimestamp, setExchangeRatesTimestamp] = useState<number>(new Date().getTime());
  const [assetsChange24h, setAssetsChange24h] = useState<Maybe<ExchangeRate>[]>([]);

  const {
    entity: exchangeRates,
    // TODO: Use when subscriptions stable
    // loading,
    queryLoading,
    error,
    refetchQuery: refetchExchangeRates,
  } = useSubscribeToEntity<
    NonNullable<ExchangeRates>,
    GetImprovedExchangeRatesQuery,
    PublishedExchangeRatesSubscription
  >({
    query: getImprovedExchangeRates,
    subscription: exchangeRatesUpdatedSubscription,
    waitsFor: !user,
    controllerTransform: afterGet,
    variables: {include24hChange: true},
  });

  useEffect(() => {
    if (exchangeRates && exchangeRates.rates) {
      const filteredRatesEndsWithUSD = exchangeRates.rates.filter(
        rate => rate && rate.pair?.endsWith(Currency.Usd),
      );

      setAssetsChange24h(filteredRatesEndsWithUSD);
    }
  }, [exchangeRates]);

  useEffect(() => {
    if (!exchangeRates || Object.keys(exchangeRates).length === 0) return;

    const formattedExchangeRates = exchangeRates.rates?.reduce(
      (acc: Record<string, number>, rate) => {
        if (rate && rate.pair) {
          acc[String(rate.pair)] = rate.value ?? 0;
        }

        return acc;
      },
      {} as Record<string, number>,
    );

    setLatestExchangeRates(formattedExchangeRates || {});
    setExchangeRatesTimestamp(new Date().getTime());
  }, [exchangeRates, setToastNotification]);

  const handleRefetchExchangeRates = useCallback(async () => {
    try {
      await refetchExchangeRates();
      setToastNotification({messageConfig: {summary: 'Exchange rates have been refreshed!'}});
    } catch (error) {
      //...
      console.error('Exchange rates update failed', error);
    }
  }, [refetchExchangeRates, setToastNotification]);

  return (
    <ExchangeRates.Provider
      value={{
        exchangeRates: latestExchangeRates,
        exchangeRatesLoading: queryLoading,
        exchangeRatesError: error,
        exchangeRatesTimestamp,
        refetchExchangeRates: handleRefetchExchangeRates,
        isEmptyExchangeRates: Object.keys(latestExchangeRates).length === 0,
        assetsChange24h,
      }}>
      {children}
    </ExchangeRates.Provider>
  );
};

export default ExchangeRatesProvider;
