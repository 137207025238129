import {useCallback, useEffect, useState} from 'react';

interface useDialogProps {
  withEsc?: boolean;
  blockScroll?: boolean;
}

const useDialog = ({withEsc = false, blockScroll = true}: useDialogProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleDialog = () => {
    if (blockScroll) {
      if (!isVisible) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }

    setIsVisible(!isVisible);
  };

  const closeWithEsc = useCallback((event: KeyboardEvent) => {
    if (event.key.toLowerCase() === 'escape') {
      setIsVisible(false);
      document.body.classList.remove('no-scroll');
    }
  }, []);

  useEffect(() => {
    if (withEsc) {
      document.addEventListener('keydown', closeWithEsc, false);
    }

    return () => {
      if (withEsc) {
        document.removeEventListener('keydown', closeWithEsc, false);
      }
    };
  }, [closeWithEsc, withEsc]);

  return {
    isVisible,
    toggleDialog,
  };
};

export {useDialog};
