import {TransitionClasses} from 'src/styled/transitions';
import styled from 'styled-components';

interface ToastProps {
  $type: 'success' | 'info' | 'warning' | 'error' | 'message';
}

const types = {
  success: {
    background: 'var(--colors-success-50)',
    borderColor: 'var(--colors-success-500)',
    color: 'var(--colors-success-500)',
  },
  info: {
    background: 'var(--colors-primary-50)',
    borderColor: 'var(--colors-primary-500)',
    color: 'var(--colors-primary-500)',
  },
  warning: {
    background: 'var(--colors-warning-50)',
    borderColor: 'var(--colors-warning-500)',
    color: 'var(--colors-warning-500)',
  },
  error: {
    background: 'var(--colors-danger-50)',
    borderColor: 'var(--colors-danger-500)',
    color: 'var(--colors-danger-500)',
  },
  message: {
    background: 'var(--colors-neutral-50)',
    borderColor: 'var(--colors-neutral-500)',
    color: 'var(--colors-neutral-500)',
  },
};

const StyledToastContent = styled.div`
  h6 {
    margin-bottom: 8px;
  }

  h6,
  p {
    flex: 1 1 100%;
  }
`;

const StyledToast = styled.div<ToastProps>`
  position: relative;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  overflow: hidden;
  background-color: ${({$type}: ToastProps) => types[$type].background};
  backdrop-filter: blur(10px);
  border-radius: 6px;
  box-shadow: var(--shadow--04dp);

  ${TransitionClasses({selector: 'toast'})}

  &,
  ${StyledToastContent} *,
  [role='icon'] {
    color: ${({$type}: ToastProps) => types[$type].color};
  }

  [role='icon'] {
    flex: 0 1 20px;
    margin-right: 16px;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 6px;
    height: 100%;
    background-color: ${({$type}: ToastProps) => types[$type].borderColor};
  }
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: var(--model-zindex-toast);
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 24px;

  ${StyledToast} {
    & + ${StyledToast} {
      margin-top: 16px;
    }
  }
`;

export {StyledToast, StyledToastContent, StyledContainer};
