import {DefaultTransition, TransitionClasses} from 'src/styled/transitions';
import styled from 'styled-components';

const StyledAlert = styled.div`
  position: relative;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: var(--colors-text-onBackground);
  border-radius: 12px;

  ${TransitionClasses({selector: 'alert'})}
`;

const StyledCTAContainer = styled.div`
  margin-left: 8px;

  a,
  button {
    display: block;
    font-family: var(--font-styles-subtitle2-family);
    font-size: var(--font-styles-subtitle2-size);
    font-style: normal;
    font-weight: var(--font-styles-subtitle2-weight);
    line-height: var(--font-styles-subtitle2-lineHeight);
    color: var(--colors-main-secondary);
    cursor: pointer;
    ${DefaultTransition()};

    &:hover {
      color: var(--colors-success-200);
    }
  }
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  z-index: var(--model-zindex-alert);
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 24px;
  transform: translateX(-50%);

  ${StyledAlert} {
    & + ${StyledAlert} {
      margin-top: 16px;
    }
  }
`;

export {StyledAlert, StyledCTAContainer, StyledContainer};
