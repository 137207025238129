import {rotate, spinnerColors, spinnerDash} from 'src/styled/keyframes';
import styled, {css} from 'styled-components';

export interface LoadingProps {
  $changeColors: boolean;
  $size: number;
  $type: 'simple' | 'double';
}

const StyledLoading = styled.div<LoadingProps>`
  position: relative;
  display: block;
  width: ${({$size}: LoadingProps) => `${$size}px`};
  height: ${({$size}: LoadingProps) => `${$size}px`};

  svg {
    stroke: var(--colors-primary-500);
    ${({$type}: LoadingProps) =>
      $type === 'double' &&
      css`
        animation: ${rotate} 1s linear infinite;
      `}

    circle {
      ${({$changeColors}: LoadingProps) =>
        $changeColors
          ? css`
              animation:
                ${spinnerDash} 1.5s ease-in-out infinite,
                ${spinnerColors} 6s ease-in-out infinite;
            `
          : css`
              animation: ${spinnerDash} 1.5s ease-in-out infinite;
            `}
    }
  }
`;

export {StyledLoading};
