import {ptToPx} from 'src/styled/utils';
import styled from 'styled-components';

interface ListProps {
  $gap: number;
  $spacing: number;
}

const StyledList = styled.ul<ListProps>`
  position: relative;
  display: grid;
  gap: ${({$gap}: ListProps) => ($gap ? `${ptToPx($gap)}px` : '4px')};
  margin: ${({$spacing}: ListProps) => ($spacing ? `${ptToPx($spacing)}px 0` : `4px 0`)};
`;

const StyledListItem = styled.li`
  position: relative;
`;

export {StyledList, StyledListItem};
