import {ReactNode} from 'react';

import type {IntRange} from 'type-fest';

import {LoadingSVG} from './LoadingSVG';
import {StyledLoading} from './styled';

export interface LoadingProps {
  changeColors?: boolean;
  size?: IntRange<0, 100>;
  type?: 'simple' | 'double';
  children?: ReactNode;
  label?: string;
}

const Loading = ({
  changeColors = false,
  size = 16,
  type = 'simple',
  children = null,
  label = 'Loading. Please, wait a moment.',
  ...props
}: LoadingProps) => (
  <StyledLoading aria-label={label} $changeColors={changeColors} $type={type} $size={size} {...props}>
    <LoadingSVG />
    {children}
  </StyledLoading>
);

export {Loading};
