import {HTMLAttributes, ReactNode} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';

import type {Color as ColorType} from '@kasta-io/tokens/src/types';
import type {IconType} from 'src/lib/types';
import type {IntRange} from 'type-fest';

import {StyledContent, StyledList, StyledListItem} from './styled';

export interface BulletListItemProps extends HTMLAttributes<HTMLLIElement> {
  children: ReactNode | string;
  id: string;
}

export interface BulletListProps extends HTMLAttributes<HTMLUListElement> {
  items: Array<BulletListItemProps>;
  gap?: IntRange<0, 100>;
  spacing?: IntRange<0, 100>;
  color?: ColorType;
  icon?: IconType;
}

const BulletList = ({
  items = [],
  gap = 1,
  spacing = 1,
  icon = {
    name: 'bulletpoint',
    type: 'custom',
  },
  color,
  ...props
}: BulletListProps) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <StyledList $gap={gap} $spacing={spacing} {...props}>
      {items.map(({id, children}: BulletListItemProps) => (
        <StyledListItem key={id}>
          <Icon type={icon.type} name={icon.name} size={24} color={color} />
          <StyledContent>{children}</StyledContent>
        </StyledListItem>
      ))}
    </StyledList>
  );
};

export {BulletList};
