import {DefaultTransition} from 'src/styled/transitions';
import {ptToPx} from 'src/styled/utils';
import styled from 'styled-components';

interface ContainerProps {
  $spacing: number;
}

interface ProgressBarProps {
  $progress: number;
  $size: 'small' | 'medium' | 'large';
}

interface ProgressIndicatorProps {
  $concluded: boolean;
  $steps: number;
  $size: 'small' | 'medium' | 'large';
}

const sizes = {
  small: `4px`,
  medium: `8px`,
  large: `16px`,
};

const StyledContainer = styled.div<ContainerProps>`
  margin: ${({$spacing}: ContainerProps) => `${ptToPx($spacing)}px 0`};
`;

const StyledContent = styled.div`
  margin-top: 16px;
  text-align: center;
`;

const StyledProgressBar = styled.div<ProgressBarProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: ${({$size}: ProgressBarProps) => sizes[$size]};
  overflow: hidden;
  background-color: var(--colors-primary-50);
  border-radius: 12px;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: ${({$progress}: ProgressBarProps) => `${$progress}%`};
    max-width: 100%;
    height: 100%;
    background-color: var(--colors-primary-500);
    transition: all linear 1s;
  }
`;

const StyledProgressIndicator = styled.ul<ContainerProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: ${({$spacing}: ContainerProps) => `${ptToPx($spacing)}px 0`};
`;

const StyledStep = styled.li<ProgressIndicatorProps>`
  width: ${({$steps}: ProgressIndicatorProps) => 100 / $steps}%;
  height: ${({$size}: ProgressIndicatorProps) => sizes[$size]};
  background-color: ${({$concluded}: ProgressIndicatorProps) =>
    $concluded ? 'var(--colors-primary-500)' : 'var(--colors-primary-50)'};
  border-radius: 12px;
  ${DefaultTransition()};
`;

export {StyledContainer, StyledContent, StyledProgressBar, StyledProgressIndicator, StyledStep};
