import styled from 'styled-components';

interface BadgeProps {
  $status: 'primary' | 'neutral' | 'warning' | 'success' | 'destructive';
  $type: 'filled' | 'accent' | 'outlined';
  $size: 'small' | 'medium' | 'large';
}

const sizes = {
  small: {
    padding: '2px',
    borderRadius: '6px',
    fontSize: 'var(--font-styles-caption2-size)',
    letterSpacing: 'var(--font-styles-caption2-spacing)',
    lineHeight: 'var(--font-styles-caption2-lineHeight)',
    icon: '12px',
  },
  medium: {
    padding: '2px 8px',
    borderRadius: '8px',
    fontSize: 'var(--font-styles-caption1-size)',
    letterSpacing: 'var(--font-styles-caption1-spacing)',
    lineHeight: 'var(--font-styles-caption1-lineHeight)',
    icon: '12px',
  },
  large: {
    padding: '4px 8px',
    borderRadius: '12px',
    fontSize: 'var(--font-styles-caption1-size)',
    letterSpacing: 'var(--font-styles-caption1-spacing)',
    lineHeight: 'var(--font-styles-caption1-lineHeight)',
    icon: '24px',
  },
};

const statuses = {
  primary: {
    filled: {
      color: 'var(--colors-text-onPrimary)',
      icon: 'var(--colors-text-onPrimary)',
      background: 'var(--colors-primary-600)',
      borderColor: 'transparent',
    },
    accent: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-primary-600)',
      background: 'var(--colors-background-darkGrey)',
      borderColor: 'transparent',
    },
    outlined: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-primary-600)',
      background: 'transparent',
      borderColor: 'var(--colors-primary-500)',
    },
  },
  neutral: {
    filled: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-text-onBackground)',
      background: 'var(--colors-text-onBackgroundLight)',
      borderColor: 'transparent',
    },
    accent: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-text-onBackgroundMedium)',
      background: 'var(--colors-background-darkGrey)',
      borderColor: 'transparent',
    },
    outlined: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-text-onBackground)',
      background: 'transparent',
      borderColor: 'var(--colors-text-onBackgroundMedium)',
    },
  },
  warning: {
    filled: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-text-onBackground)',
      background: 'var(--colors-warning-900)',
      borderColor: 'transparent',
    },
    accent: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-warning-900)',
      background: 'var(--colors-background-darkGrey)',
      borderColor: 'transparent',
    },
    outlined: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-warning-900)',
      background: 'transparent',
      borderColor: 'var(--colors-main-accent)',
    },
  },
  success: {
    filled: {
      color: 'var(--colors-text-onPrimary)',
      icon: 'var(--colors-text-onPrimary)',
      background: 'var(--colors-main-secondary)',
      borderColor: 'transparent',
    },
    accent: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-main-secondary)',
      background: 'var(--colors-background-darkGrey)',
      borderColor: 'transparent',
    },
    outlined: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-main-secondary)',
      background: 'transparent',
      borderColor: 'var(--colors-main-secondary)',
    },
  },
  destructive: {
    filled: {
      color: 'var(--colors-text-onPrimary)',
      icon: 'var(--colors-text-onPrimary)',
      background: 'var(--colors-danger-500)',
      borderColor: 'transparent',
    },
    accent: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-danger-500)',
      background: 'var(--colors-background-darkGrey)',
      borderColor: 'transparent',
    },
    outlined: {
      color: 'var(--colors-text-onBackground)',
      icon: 'var(--colors-danger-500)',
      background: 'transparent',
      borderColor: 'var(--colors-danger-500)',
    },
  },
};

const StyledBadge = styled.span<BadgeProps>`
  display: inline-flex;
  flex-flow: row wrap;
  gap: 2px;
  align-items: center;
  justify-content: center;
  padding: ${({$size}: BadgeProps) => $size && sizes[$size].padding};
  font-size: ${({$size}: BadgeProps) => $size && sizes[$size].fontSize};
  line-height: ${({$size}: BadgeProps) => $size && sizes[$size].lineHeight};
  color: ${({$status, $type}: BadgeProps) => $status && $type && statuses[$status][$type].color};
  text-align: center;
  letter-spacing: ${({$size}: BadgeProps) => $size && sizes[$size].letterSpacing};
  background-color: ${({$status, $type}: BadgeProps) =>
    $status && $type && statuses[$status][$type].background};
  backdrop-filter: blur(4px);
  border: 1px solid;
  border-color: ${({$status, $type}: BadgeProps) => $status && $type && statuses[$status][$type].borderColor};
  border-radius: ${({$size}: BadgeProps) => $size && sizes[$size].borderRadius};

  [role='icon'] {
    width: ${({$size}: BadgeProps) => $size && sizes[$size].icon};
    height: ${({$size}: BadgeProps) => $size && sizes[$size].icon};
    font-size: ${({$size}: BadgeProps) => $size && sizes[$size].icon};
    color: ${({$status, $type}: BadgeProps) => statuses[$status][$type].icon};
  }
`;

export {StyledBadge};
