import {HTMLAttributes} from 'react';

import {IntRange} from 'type-fest';

import {StyledDivider} from './styled';

export interface DividerProps extends HTMLAttributes<HTMLHRElement> {
  height?: IntRange<0, 100>;
  spacing?: IntRange<0, 100>;
  background?: string;
}

const Component = ({
  height = 1,
  spacing = 1,
  background = 'var(--colors-text-onBackgroundLight)',
  ...props
}) => <StyledDivider $height={height} $spacing={spacing} $background={background} {...props} />;

export {Component as Divider};
