import {useContext} from 'react';

import {AlertContext} from 'src/contexts/alert';

const useAlert = () => {
  const alertHelpers = useContext(AlertContext);
  return alertHelpers;
};

export {useAlert};
