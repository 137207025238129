import {StaticImageData} from 'next/image';

import {Button, Icon, Typography} from 'src/index';

import type {ThemeType} from 'src/lib/types';

import {ContainerButton, StyledContainer, StyledContent, StyledContentImage} from './styled';

export interface BannerProps {
  title: string;
  description: string;
  type?: ThemeType;
  imageUrl: string | StaticImageData;
  isClosable?: boolean;
  onClose?: () => void;
}

const Banner = ({
  title = '',
  description = '',
  type = 'primary',
  imageUrl = '',
  isClosable = true,
  onClose = () => null,
}: BannerProps) => (
  <StyledContainer $type={type}>
    {isClosable && (
      <ContainerButton>
        <Button ghost noPadding onClick={onClose}>
          <Icon name="close-big" size={24} />
        </Button>
      </ContainerButton>
    )}

    <StyledContent>
      <Typography as="h6" variant="h6" weight="bold">
        {title}
      </Typography>

      <Typography variant="caption1">{description}</Typography>
    </StyledContent>

    <StyledContentImage $imageUrl={imageUrl}></StyledContentImage>
  </StyledContainer>
);

export {Banner};
