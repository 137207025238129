import styled from 'styled-components';

import type {Color as ColorType} from '@kasta-io/tokens/src/types';

export interface TypographyProps {
  $variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'h7'
    | 'button1'
    | 'button2'
    | 'body1'
    | 'body2'
    | 'subtitle1'
    | 'subtitle2'
    | 'subtitle3'
    | 'caption1'
    | 'caption2'
    | 'overline';
  $weight?: string | undefined;
  $color?: ColorType;
  $transform?: 'none' | 'lowercase' | 'uppercase' | 'capitalize';
}

const StyledTypography = styled.p<TypographyProps>`
  font-family: ${({$variant}: TypographyProps) => $variant && `var(--font-styles-${$variant}-family)`};
  font-size: ${({$variant}: TypographyProps) => $variant && `var(--font-styles-${$variant}-size)`};
  font-style: normal;
  font-weight: ${({$variant, $weight}: TypographyProps) =>
    $weight ? $weight : `var(--font-styles-${$variant}-weight)`};
  line-height: ${({$variant}: TypographyProps) => $variant && `var(--font-styles-${$variant}-lineHeight)`};
  color: ${({$color}: TypographyProps) => ($color ? $color : 'var(--colors-text-onBackground)')};
  text-transform: ${({$transform}: TypographyProps) => ($transform ? $transform : 'none')};
  letter-spacing: ${({$variant}: TypographyProps) => $variant && `var(--font-styles-${$variant}-spacing)`};
`;

export {StyledTypography};
