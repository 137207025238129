import {skeletonPulse, skeletonWave} from 'src/styled/keyframes';
import styled, {css} from 'styled-components';

interface ComponentProps {
  $animation?: 'pulse' | 'wave';
  $height?: number | undefined;
  $width?: number | undefined;
  $borderRadius?: number | undefined;
  $circle?: boolean | undefined;
  $spacing?: number;
}

const StyledSkeleton = styled.span<ComponentProps>`
  position: relative;
  display: inline-flex;
  width: ${({$width}: ComponentProps) => ($width ? `${$width}px` : '100%')};
  height: ${({$height}: ComponentProps) => ($height ? `${$height}px` : 'auto')};
  margin: ${({$spacing}: ComponentProps) => ($spacing ? `${$spacing}px` : '0')};
  overflow: hidden;
  line-height: 1;
  color: transparent;
  pointer-events: none;
  user-select: none;
  background-color: var(--colors-background-default);
  border-radius: ${({$borderRadius, $circle}: ComponentProps) =>
    $circle ? '50%' : $borderRadius ? `${$borderRadius}px` : '4px'};

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: var(--models-zindex-base);
    display: block;
    height: 100%;
  }

  ${({$animation}: ComponentProps) =>
    $animation === 'wave'
      ? css`
          &:after {
            background-image: linear-gradient(
              90deg,
              var(--colors-background-default) 0%,
              var(--colors-text-onPrimaryLight) 50%,
              var(--colors-background-default) 100%
            );
            background-repeat: no-repeat;
            transform: translateX(-100%);
            animation: ${skeletonWave} ease-in-out 1.5s infinite;
          }
        `
      : css`
          background-color: var(--colors-background-default);
          animation: 2s ${skeletonPulse} ease-in-out 500ms infinite;
        `}
`;

const StyledContainer = styled.div`
  ${StyledSkeleton} {
    & + ${StyledSkeleton} {
      margin-top: 8px;
    }
  }
`;

export {StyledContainer, StyledSkeleton};
