import {useEffect} from 'react';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';

import {SPLASH_SCREEN_PATH} from '@/constants';
import {useUser} from '@/contexts/UserContext';
import {useAuthenticationServices} from '@/hooks/login/useAuthenticationServices';
import useFeatureFlagRoutesControl from '@/hooks/useFeatureFlagRoutesControl';
import {errorMonitoringService} from '@/services/error/ErrorMonitoringService';
import {pinService} from '@/services/pin/PinService';
import {tracking} from '@/services/tracking/TrackingService';
import {goTo} from '@/utils/navigation';

import type {ReactNode} from 'react';

const DynamicSplash = dynamic(() => import('@/components/Splash/Splash'));

export const DIRECT_ACCESS_ROUTES = [
  '/login',
  'signup',
  '/404',
  '/_offline',
  '/dynamic',
  '/500',
  '/country-not-available',
  '/forgot-pin',
];

function PrivateRouteSafetyControl({children}: Readonly<{children: ReactNode}>) {
  const {pathname} = useRouter();
  const {fbUser, setFBUserOnce} = useUser();
  const isRouteWithoutControl = DIRECT_ACCESS_ROUTES.some(route => pathname.includes(route));
  useFeatureFlagRoutesControl(pathname);
  const {userService} = useAuthenticationServices();

  useEffect(() => {
    const fetchLocation = async () => {
      const location = await userService.getUserLocationAndIP();

      errorMonitoringService.setUser({
        userId: fbUser?.uid,
        displayName: fbUser?.displayName || undefined,
        location: location?.geo,
      });
    };

    fetchLocation();
  }, [fbUser?.displayName, fbUser?.uid, userService]);

  // This effect handle change of route from protected
  // to login screen if fbUser gets to falsy
  useEffect(() => {
    const mustInsertSoftPin = pinService.isPinTimedOut();

    // We avoid control
    if (isRouteWithoutControl) {
      return;
    }

    // If the user is forced to input pin again and there was any record of
    // last successful pin I was for inactivity, and then send the event
    const lastPinTimestamp = pinService.getLastPinTimestamp();
    if (mustInsertSoftPin && lastPinTimestamp) {
      tracking.logEvent(tracking.events.inactivity_lock_app());
    }

    // We redirect to login screen
    if (fbUser?.isAnonymous || mustInsertSoftPin) {
      goTo('/login');
      return;
    }

    if (pathname === SPLASH_SCREEN_PATH) {
      goTo('/home');
    }
  }, [fbUser, pathname, isRouteWithoutControl, userService]);

  if ((!setFBUserOnce || !fbUser) && !isRouteWithoutControl) {
    return <DynamicSplash />;
  }

  return <>{children}</>;
}

export default PrivateRouteSafetyControl;
