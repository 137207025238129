import {ReactNode} from 'react';

import {IntRange} from 'type-fest';

import {StyledContainer, StyledContent, StyledProgressBar} from './styled';

export interface ProgressBarProps {
  progress?: number;
  children?: ReactNode;
  spacing?: IntRange<0, 100>;
  size?: 'small' | 'medium' | 'large';
}

const ProgressBar = ({
  progress = 0,
  spacing = 1,
  size = 'small',
  children = null,
  ...props
}: ProgressBarProps) => (
  <StyledContainer $spacing={spacing}>
    <StyledProgressBar $progress={progress} $size={size} {...props}></StyledProgressBar>

    <StyledContent>{children}</StyledContent>
  </StyledContainer>
);

export {ProgressBar};
