import {growAndHide} from 'src/styled/keyframes';
import styled from 'styled-components';

interface ContainerProps {
  $hasAnimation: boolean;
  $backgroundColor: string;
}

const StyledSplashScreen = styled.div<ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({$backgroundColor}: ContainerProps) =>
    $backgroundColor || 'var(--colors-background-default)colors'};

  img {
    z-index: var(--model-zindex-base);
    display: block;
    width: 96px;
    height: 96px;
    margin: 0 auto;
  }

  &:before {
    position: absolute;
    top: calc(50% - 44px);
    left: calc(50% - 44px);
    display: ${({$hasAnimation}: ContainerProps) => ($hasAnimation ? 'block' : 'none')};
    width: 88px;
    height: 88px;
    background-color: var(--colors-primary-200);
    border-radius: 20px;
    animation: ${growAndHide} ease-in-out 2.5s infinite;
  }
`;

export {StyledSplashScreen};
