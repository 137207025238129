import styled, {css} from 'styled-components';

interface AvatarProps {
  $size: 'small' | 'medium' | 'large';
  $hasImage?: boolean;
  $clickable?: boolean;
}

const sizes = {
  small: 24,
  medium: 32,
  large: 56,
};

const StyledAvatar = styled.div<AvatarProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({$size}: AvatarProps) => `${sizes[$size]}px`};
  height: ${({$size}: AvatarProps) => `${sizes[$size]}px`};
  overflow: hidden;
  background-color: var(--colors-main-secondary);
  border-radius: 8px;

  ${({$clickable}: AvatarProps) =>
    $clickable &&
    css`
      cursor: pointer;
    `}

  p {
    color: var(--colors-text-onPrimary);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({$hasImage}: AvatarProps) =>
    $hasImage &&
    css`
      p {
        font-size: 0;
      }
    `}
`;

export {StyledAvatar};
