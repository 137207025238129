import {StaticImageData} from 'next/image';

import {DefaultBlockRadius} from 'src/styled/mixins';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 16px;
  background-color: var(--colors-background-paper);
  ${DefaultBlockRadius}
  box-shadow: var(--shadow--06dp);
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 200px;
`;

export const StyledImageContainer = styled.div<{$imageUrl: string | StaticImageData}>`
  width: 72px;
  height: 72px;
  background-image: ${({$imageUrl}) => `url(${typeof $imageUrl === 'string' ? $imageUrl : $imageUrl.src})`};
  background-repeat: no-repeat;
  background-size: contain;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const StyledStepContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;
