import {ReactNode, useState} from 'react';

import {IntRange} from 'type-fest';

import {StyledButton, StyledContent, StyledNav, StyledNavItem, StyledTabs} from './styled';

export interface TabItemProps {
  id: string;
  header: string;
  content: ReactNode;
  disabled?: boolean;
}

export interface TabsProps {
  tabs: Array<TabItemProps>;
  spacing?: IntRange<0, 100>;
  style?: 'flat' | 'pill';
}

const Tabs = ({tabs = [], spacing = 1, style = 'flat', ...props}: TabsProps) => {
  const [selectedTabId, setSelectedTabId] = useState<string>(tabs[0]?.id || '');
  const selectedTab = tabs.find(({id}: TabItemProps) => id === selectedTabId);

  if (tabs.length === 0) {
    return null;
  }

  return (
    <StyledTabs $spacing={spacing} {...props}>
      <StyledNav $style={style}>
        {tabs.map((tab: TabItemProps) => (
          <StyledNavItem key={tab.id}>
            <StyledButton
              $style={style}
              $active={selectedTabId === tab.id}
              disabled={tab.disabled}
              onClick={() => setSelectedTabId(tab.id)}>
              {tab.header}
            </StyledButton>
          </StyledNavItem>
        ))}
      </StyledNav>

      {selectedTab && <StyledContent>{selectedTab.content}</StyledContent>}
    </StyledTabs>
  );
};

export {Tabs};
