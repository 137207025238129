import {Fragment, ReactNode} from 'react';

import {NonNegative} from 'type-fest';

import {StyledContainer, StyledSkeleton} from './styled';

export interface SkeletonProps {
  circle?: boolean;
  count?: NonNegative<number>;
  borderRadius?: NonNegative<number>;
  width?: NonNegative<number>;
  height?: NonNegative<number>;
  children?: ReactNode;
  spacing?: NonNegative<number>;
  animation?: 'pulse' | 'wave';
}

const render = ({
  animation,
  borderRadius,
  spacing,
  width,
  height,
  circle,
  children,
  ...props
}: SkeletonProps) => (
  <StyledSkeleton
    $animation={animation}
    $width={width}
    $height={height}
    $borderRadius={borderRadius}
    $circle={circle}
    $spacing={spacing}
    {...props}>
    {children}
  </StyledSkeleton>
);

const Skeleton = ({
  count = 1,
  animation = 'wave',
  borderRadius = 4,
  spacing = 0,
  children = '&zwnj;',
  ...props
}: SkeletonProps) => {
  const skeletonsArray: Array<number> = Array.from({length: count}, (x, i) => i);

  if (count > 1) {
    return (
      <StyledContainer>
        {skeletonsArray.map((item: number) => (
          <Fragment key={item}>{render({animation, children, spacing, borderRadius, ...props})}</Fragment>
        ))}
      </StyledContainer>
    );
  }

  return render({animation, children, spacing, borderRadius, ...props});
};

export {Skeleton};
