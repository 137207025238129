import {StaticImageData} from 'next/image';

import {ProgressIndicator} from 'src/atoms/Progress/ProgressIndicator';
import {Typography} from 'src/atoms/Typography/Typography';

import {
  StyledContainer,
  StyledFooterContainer,
  StyledHeaderContainer,
  StyledHeaderContent,
  StyledImageContainer,
  StyledStepContainer,
} from './styled';

export interface ProgressBannerProps {
  title: string;
  description: string;
  currentStep: number;
  totalSteps: number;
  imageUrl: string | StaticImageData;
  testId?: string;
}

const ProgressBanner = ({
  title = '',
  description = '',
  imageUrl = '',
  currentStep = 0,
  totalSteps = 3,
  ...props
}: Partial<ProgressBannerProps>) => {
  return (
    <StyledContainer {...props}>
      <StyledHeaderContainer>
        <StyledHeaderContent>
          <Typography variant="body2" color="var(--colors-neutral-600)">
            {title}
          </Typography>
          <Typography variant="h6" weight="bold" color="var(--colors-neutral-600)">
            {description}
          </Typography>
        </StyledHeaderContent>

        <StyledImageContainer role="img" $imageUrl={imageUrl} />
      </StyledHeaderContainer>

      <StyledFooterContainer>
        <StyledStepContainer>
          <ProgressIndicator spacing={1} concluded={currentStep} steps={totalSteps} />
        </StyledStepContainer>

        <Typography variant="caption1" color="var(--colors-text-onBackgroundMedium)">
          {`${currentStep}/${totalSteps}`}
        </Typography>
      </StyledFooterContainer>
    </StyledContainer>
  );
};

export {ProgressBanner};
