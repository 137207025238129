import {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {Banner} from '@/graphql/__generated__/graphql';

import {getBanners} from '@/graphql/queries';
import {useQuery} from '@apollo/client';

import {useAppConfig} from './AppConfigContext';

type BannersContextType = {
  banners: Banner[];
  handleCloseBanner: (bannerFeatureId: string) => void;
};

const TEMP_STORAGE_BANNER_KEY = 'hiddenBanners';

const BannersContext = createContext<BannersContextType>({
  banners: [],
  handleCloseBanner: () => {},
});

export const useBanners = () => useContext(BannersContext);

const BannersContextProvider = ({children}: {children: ReactNode}) => {
  const [hiddenBanners, setHiddenBanners] = useState<string[]>([]);
  const appConfig = useAppConfig();
  const {data} = useQuery(getBanners);

  /**
   * Fetch hidden banners from local storage
   * @returns {string[]} Array of hidden banner IDs
   */
  const getHiddenBanners = (): string[] => {
    const currentBannersHidden = localStorage.getItem(TEMP_STORAGE_BANNER_KEY);
    return currentBannersHidden ? JSON.parse(currentBannersHidden) : [];
  };

  /**
   * Handle closing a banner by adding its ID to the hidden banners list
   * @param {string} bannerFeatureId - The ID of the banner to hide
   */
  const handleCloseBanner = useCallback(
    (bannerFeatureId: string) => {
      const currentBannersHidden = getHiddenBanners();
      const newBannersHidden = [...currentBannersHidden, bannerFeatureId];

      localStorage.setItem(TEMP_STORAGE_BANNER_KEY, JSON.stringify(newBannersHidden));
      setHiddenBanners(newBannersHidden);
    },
    [setHiddenBanners],
  );

  /**
   * Filter banners based on feature flags in appConfig
   * @param {Banner} banner - The banner to filter
   * @returns {boolean} Whether the banner should be shown
   */
  const filterByFeature = useCallback(
    (banner: Banner) => {
      if (!appConfig || Object.keys(appConfig).length === 0) {
        return true;
      }
      const [feature] = banner?.feature.split('-');
      const result = appConfig[(feature + 'Enabled') as keyof typeof appConfig];
      return result !== false;
    },
    [appConfig],
  );

  /**
   * Memoized list of banners filtered by feature and hidden status
   */
  const banners = useMemo(() => {
    if (!data?.getBanners) {
      return [];
    }
    return data.getBanners
      .filter((banner: Banner | null) => banner && filterByFeature(banner))
      .filter((banner: Banner | null) => banner && !hiddenBanners.includes(banner.feature)) as Banner[];
  }, [data, hiddenBanners, filterByFeature]);

  /**
   * Initialize hidden banners from local storage on component mount
   */
  useEffect(() => {
    const initialHiddenBanners = getHiddenBanners();
    setHiddenBanners(initialHiddenBanners);
  }, []);

  return <BannersContext.Provider value={{banners, handleCloseBanner}}>{children}</BannersContext.Provider>;
};

export default BannersContextProvider;
