import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';
import {IntRange} from 'type-fest';

import {StyledContainer, StyledOption, StyledOptionsList} from './styled';

export interface DropdownItemProps {
  id: string;
  label: ReactNode | string;
  value: string;
  disabled?: boolean;
}

export interface DropdownProps {
  items: Array<DropdownItemProps>;
  spacing?: IntRange<0, 100>;
  closeWithEsc?: boolean;
  disabled?: boolean;
  placeholder: string;
}

const Dropdown = ({
  placeholder = '',
  spacing = 0,
  items = [],
  closeWithEsc = true,
  disabled = false,
  ...props
}: DropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItemProps | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const hasItems = items.length > 0;

  const closeDropdown = useCallback(
    (event: MouseEvent | KeyboardEvent): void => {
      if (
        (event instanceof MouseEvent && !containerRef?.current?.contains(event.target as Node)) ||
        (event instanceof KeyboardEvent && event.key.toLowerCase() === 'escape')
      ) {
        setOpen(false);
      }
    },
    [setOpen],
  );

  const selectItem = useCallback(
    (item: DropdownItemProps) => {
      setSelectedItem(item);
      setOpen(false);
    },
    [setSelectedItem, setOpen],
  );

  useEffect(() => {
    if (!open) {
      document.addEventListener('click', closeDropdown);
      closeWithEsc && document.addEventListener('keyup', closeDropdown);
    } else {
      document.removeEventListener('click', closeDropdown);
      closeWithEsc && document.addEventListener('keyup', closeDropdown);
    }

    return () => {
      document.removeEventListener('click', closeDropdown);
      closeWithEsc && document.addEventListener('keyup', closeDropdown);
    };
  }, [closeWithEsc]);

  if (!hasItems) {
    return null;
  }

  return (
    <StyledContainer ref={containerRef} $disabled={disabled} $spacing={spacing} {...props}>
      <button onClick={() => setOpen(!open)}>
        <span>{selectedItem?.value || placeholder}</span>

        <Icon
          name={open ? 'chevron-up' : 'chevron-down'}
          size={24}
          color={disabled ? 'var(--colors-text-onBackgroundMedium)' : 'var(--colors-primary-500)'}
        />
      </button>

      {open && (
        <StyledOptionsList>
          {items.map((item: DropdownItemProps) => (
            <StyledOption
              key={item.id}
              $disabled={item?.disabled}
              $selected={item.id === selectedItem?.id}
              onClick={() => selectItem(item)}>
              {item.label}
            </StyledOption>
          ))}
        </StyledOptionsList>
      )}
    </StyledContainer>
  );
};

export {Dropdown};
