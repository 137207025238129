import {ReactNode} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';

import type {IconType, ThemeType} from 'src/lib/types';
import type {IntRange} from 'type-fest';

import {StyledCTAContainer, StyledPill} from './styled';

export interface PillProps {
  spacing?: IntRange<0, 100>;
  cta?: ReactNode;
  children?: ReactNode;
  type?: ThemeType;
  icon?: IconType;
}

const Pill = ({
  spacing = 1,
  cta = null,
  children = null,
  type = 'primary',
  icon = {
    name: 'warning',
    type: 'kastaicon',
  },
  ...props
}: PillProps) => (
  <StyledPill $spacing={spacing} $type={type} {...props}>
    <Icon type={icon.type} name={icon.name} size={20} />

    {children}
    {cta !== null && <StyledCTAContainer>{cta}</StyledCTAContainer>}
  </StyledPill>
);

export {Pill};
