import {MouseEvent as ReactMouseEvent, useRef} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';
import {Input} from 'src/atoms/Input/Input';
import {StyledAssistiveText, StyledLabel} from 'src/atoms/Input/styled';
import {IntRange} from 'type-fest';

import type {InputProps} from 'src/atoms/Input/Input';

import {StyledContainer, StyledCopyButton, StyledTextField} from './styled';

export interface TextFieldProps extends InputProps {
  label?: string;
  floatingLabel?: boolean;
  spacing?: IntRange<0, 100>;
  copy?: boolean;
  onCopyText?: (text?: string, e?: ReactMouseEvent) => void;
}

const TextField = ({
  label = '',
  floatingLabel = true,
  spacing = 0,
  copy = false,
  onCopyText = () => null,
  ...props
}: TextFieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOnCopy = async (event: ReactMouseEvent) => {
    const text = inputRef.current?.value || '';

    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      inputRef.current?.select();
      document?.execCommand('copy');
    }

    return onCopyText(text, event);
  };

  return (
    <StyledContainer
      $hasCopy={copy}
      $spacing={spacing}
      $hasError={!!props?.error}
      $hasFloatingLabel={floatingLabel}>
      <StyledTextField>
        {label !== '' && !floatingLabel && (
          <StyledLabel htmlFor={props.name} $floating={floatingLabel}>
            {label}
          </StyledLabel>
        )}

        <Input ref={inputRef} floatingLabel={floatingLabel} {...props} />

        {label !== '' && floatingLabel && (
          <StyledLabel $floating={floatingLabel} data-floating={true}>
            {label}
          </StyledLabel>
        )}
      </StyledTextField>

      {props?.error && <StyledAssistiveText>{props.error}</StyledAssistiveText>}

      {copy && (
        <StyledCopyButton aria-label="Copy to clipboard" onClick={handleOnCopy}>
          <Icon name="copy" size={20} />
        </StyledCopyButton>
      )}
    </StyledContainer>
  );
};

export {TextField};
