import {ptToPx} from 'src/styled/utils';
import styled from 'styled-components';

interface BulletListProps {
  $gap: number;
  $spacing: number;
}

const StyledList = styled.ul<BulletListProps>`
  display: grid;
  gap: ${({$gap}: BulletListProps) => ($gap ? `${ptToPx($gap)}px` : `4px`)};
  margin: ${({$spacing}: BulletListProps) => ($spacing ? `${ptToPx($spacing)}px 0` : `4px 0`)};
`;

const StyledListItem = styled.li`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

const StyledContent = styled.div`
  position: relative;
  flex: 1;
  padding-left: 8px;
`;

export {StyledList, StyledListItem, StyledContent};
