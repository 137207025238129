import Image from 'next/image';

import {Button, Dialog, Typography} from '@kasta-io/components';

import s from './KycVerificationInProgressDialog.module.scss';

interface Props {
  type: 'cash' | 'crypto';
  isVisible: boolean;
  toggleDialog: () => void;
}

export const KycVerificationInProgressDialog = ({type, isVisible, toggleDialog}: Props) => {
  return (
    <Dialog open={isVisible} popOver onClose={toggleDialog} closeOnBackdropClick hasCloseButton={false}>
      <div className={s.container}>
        <Image src="/images/yellow-column.png" width={80} height={80} alt="" quality={100} />
        <Typography variant="h6" as="h6">
          Verification in progress
        </Typography>

        <Typography variant="body2" color="var(--colors-text-onBackgroundMedium)">
          {type === 'crypto'
            ? "Verifying your info. We'll email you once it's done."
            : "Verifying your info. We'll email you once it's done, so you can proceed with cash activation."}
        </Typography>

        <Button className={s.button} onClick={toggleDialog}>
          Close
        </Button>
      </div>
    </Dialog>
  );
};
