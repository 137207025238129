import {ptToPx} from 'src/styled/utils';
import styled, {css} from 'styled-components';

interface ContainerProps {
  $spacing: number;
  $hasCopy: boolean;
  $hasError: boolean;
  $hasFloatingLabel: boolean;
}

const StyledCopyButton = styled.button`
  position: absolute;
  top: 50%;
  right: 16px;
  cursor: pointer;
`;

const StyledContainer = styled.div<ContainerProps>`
  position: relative;
  margin-bottom: ${({$spacing}: ContainerProps) => `${ptToPx($spacing)}px`};

  ${({$hasFloatingLabel}: ContainerProps) =>
    $hasFloatingLabel &&
    css`
      ${StyledCopyButton} {
        transform: translateY(-50%);
      }
    `}

  ${({$hasError, $hasFloatingLabel}: ContainerProps) =>
    $hasError &&
    css`
      ${StyledCopyButton} {
        transform: ${$hasFloatingLabel ? 'translateY(calc(-50% - 8px))' : 'translateY(-8px)'};
      }
    `}
`;

const StyledTextField = styled.div`
  position: relative;
`;

export {StyledContainer, StyledTextField, StyledCopyButton};
