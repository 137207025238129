import {IntRange} from 'type-fest';

import {StyledProgressIndicator, StyledStep} from './styled';

export interface ProgressIndicatorProps {
  steps: number;
  concluded: number;
  spacing?: IntRange<0, 100>;
  size?: 'small' | 'medium' | 'large';
}

const ProgressIndicator = ({
  steps = 4,
  concluded = 0,
  spacing = 1,
  size = 'small',
  ...props
}: ProgressIndicatorProps) => {
  const stepsArray: Array<number> = Array.from({length: steps}, (x, i) => i);

  if (steps === 0) {
    return null;
  }

  return (
    <StyledProgressIndicator $spacing={spacing} aria-hidden={true} {...props}>
      {stepsArray.map((step: number) => (
        <StyledStep
          key={step}
          $steps={steps}
          $concluded={step <= concluded - 1}
          $size={size}
          data-concluded={step <= concluded - 1}
        />
      ))}
    </StyledProgressIndicator>
  );
};

export {ProgressIndicator};
