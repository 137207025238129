import {MouseEvent as ReactMouseEvent} from 'react';

import {Badge, BadgeProps} from 'src/atoms/Badge/Badge';
import {Icon} from 'src/atoms/Icon/Icon';
import {Typography} from 'src/atoms/Typography/Typography';

import type {KastaIconProps} from 'src/atoms/Icon/Icon';

import {
  StyledBadgesContainer,
  StyledContentWrapper,
  StyledList,
  StyledListItem,
  StyledWrapper,
} from './styled';

export interface ActionListProps {
  actions: Array<ActionListItemProps>;
}

export interface ActionListItemProps {
  id: string;
  title: string;
  description?: string;
  icon: KastaIconProps['name'];
  available?: boolean;
  type: 'button' | 'link';
  link?: object;
  onClick?: (e: ReactMouseEvent) => void;
  badges?: Array<BadgeProps>;
}

const ActionList = ({actions = [], ...props}: ActionListProps) => {
  if (actions.length === 0) {
    return null;
  }

  return (
    <StyledList {...props}>
      {actions.map(
        ({
          id = '',
          title = '',
          description = '',
          icon = '',
          type = 'button',
          link = {},
          onClick = () => null,
          available = true,
          badges = [],
        }: ActionListItemProps) => {
          const wrapperType = type === 'link' ? 'a' : 'div';
          const wrapperProps = wrapperType === 'a' ? {...link} : {onClick};

          return (
            <StyledListItem key={id} $disabled={!available}>
              <StyledWrapper as={wrapperType} {...wrapperProps} data-testid={`action-${id}-wrapper`}>
                {icon !== '' && <Icon name={icon} size={24} />}

                <StyledContentWrapper>
                  <Typography as="h6" variant="subtitle2" color="var(--colors-text-onBackgroundMedium)">
                    {title}
                  </Typography>
                  <Typography as="p" variant="caption1" color="var(--colors-text-onBackgroundLight)">
                    {description}
                  </Typography>
                  {badges.length > 0 && (
                    <StyledBadgesContainer>
                      {badges.map((badge: BadgeProps) => (
                        <Badge {...badge}>{badge?.children}</Badge>
                      ))}
                    </StyledBadgesContainer>
                  )}
                </StyledContentWrapper>

                {!available ? <small>Unavailable</small> : <Icon name="chevron-right" size={24} />}
              </StyledWrapper>
            </StyledListItem>
          );
        },
      )}
    </StyledList>
  );
};

export {ActionList};
