import {ReactNode} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';
import {Typography} from 'src/atoms/Typography/Typography';

import type {IconType} from 'src/lib/types';

import {StyledExplainer, StyledHeader} from './styled';

export interface ExplainerProps {
  title?: ReactNode;
  icon?: IconType;
  children?: ReactNode;
}

const Explainer = ({
  title = '',
  icon = {
    name: 'warning',
    type: 'kastaicon',
  },
  children,
  ...props
}: ExplainerProps) => (
  <StyledExplainer {...props}>
    <StyledHeader>
      {icon && <Icon type={icon.type} name={icon.name} backgroundColor={icon?.backgroundColor} size={48} />}
      {title !== '' && (
        <Typography as="h6" variant="h7" color="var(--colors-text-onBackgroundMedium)">
          {title}
        </Typography>
      )}
    </StyledHeader>

    {children}
  </StyledExplainer>
);

export {Explainer};
