import {FormEvent, forwardRef, HTMLAttributes} from 'react';

import {StyledRadio} from './styled';

export interface RadioInputProps extends HTMLAttributes<HTMLInputElement> {
  name: string;
  id?: string;
  disabled?: boolean;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
}

const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  ({name = '', id = '', disabled = false, ...props}, ref) => (
    <StyledRadio ref={ref} type="radio" name={name} id={id} disabled={disabled} {...props} />
  ),
);

export {RadioInput};
