import {MouseEvent as ReactMouseEvent} from 'react';

import {Typography} from 'src/atoms/Typography/Typography';
import {ActionList} from 'src/molecules/ActionList/ActionList';

import type {ActionListItemProps} from 'src/molecules/ActionList/ActionList';
import type {DialogProps} from 'src/molecules/Dialog/Dialog';

import {StyledContent, StyledDialog} from './styled';

export interface ActionDialogProps extends DialogProps {
  title: string;
  onClose?: (e: ReactMouseEvent) => void;
  actions: Array<ActionListItemProps>;
}

const ActionDialog = ({title = '', onClose = () => null, actions = [], ...props}: ActionDialogProps) => {
  if (actions.length === 0) {
    return null;
  }

  return (
    <StyledDialog
      title={
        <Typography as="h6" variant="h6">
          {title}
        </Typography>
      }
      elevation={true}
      onClose={onClose}
      {...props}>
      <StyledContent>
        <ActionList actions={actions} />
      </StyledContent>
    </StyledDialog>
  );
};

export {ActionDialog};
