import {useCallback, useEffect, useMemo, useState} from 'react';

import {ScaScope as ScaScopeType} from '@/types/scaScope';

import {SCA_BALANCE_DESCRIPTION, SCA_HISTORY_DESCRIPTION, ScaScope} from '@/constants';
import {useAppConfig} from '@/contexts/AppConfigContext';
import {useComplianceContext} from '@/contexts/ComplianceContext';
import {useDialogContext} from '@/contexts/DialogContext';
import {useUser} from '@/contexts/UserContext';
import {useSCAQuery} from '@/hooks/useSCAQuery';
import {isCashAccountActivated as isAccountActivated} from '@/utils/cash';

import CashSCADialogBody from '@/components/ui/dialogs/cash/CashSCADialogBody/CashSCADialogBody';

import type {CashAuthContext} from '@/types/cashAuthContext';

export interface Return extends CashAuthContext {
  onConfirm: () => void;
  closeSCAModal: () => void;
  onCloseModal: () => void;
  customDescription?: string;
}

interface Props {
  scope: ScaScopeType;
}

export const useCashAuthProvider = ({scope}: Props) => {
  const [customDescription, setCustomDescription] = useState<string>(
    scope === ScaScope.Balance ? SCA_BALANCE_DESCRIPTION : SCA_HISTORY_DESCRIPTION,
  );
  const [isSCARequired, setIsSCARequired] = useState(true);
  const [isSCADelayed, setIsSCADelayed] = useState(false);

  const {appConfig} = useAppConfig();

  const {cashEnabledPwa} = appConfig;

  const {user} = useUser();
  const {canSkipKyc} = useComplianceContext();
  const isCashAccountActivated = cashEnabledPwa && canSkipKyc && isAccountActivated(user?.cashAccountStatus);

  const {setDialog, hideDialog} = useDialogContext();

  const {
    getIntergiroSCA,
    refetchSCAIfNeeded,
    refetch: refetchSCA,
    data,
    loading: isSCALoading,
  } = useSCAQuery(scope, !isCashAccountActivated);

  useEffect(() => {
    if (!isCashAccountActivated) {
      setIsSCARequired(false);
      return;
    }

    const isSCARequiredNow = data ? isCashAccountActivated && !!data.redirectUrl : isCashAccountActivated;
    if (isSCARequired !== isSCARequiredNow) {
      setIsSCARequired(isSCARequiredNow);
    }
  }, [isCashAccountActivated, data, isSCARequired, scope]);

  const openSCALink = useCallback(async () => {
    if (!isCashAccountActivated) {
      return;
    }

    if (isSCALoading) {
      return setIsSCADelayed(true);
    }

    const _data = await refetchSCAIfNeeded();
    if (_data?.redirectUrl) {
      window.location.href = _data?.redirectUrl;
    }
  }, [isCashAccountActivated, isSCALoading, refetchSCAIfNeeded]);

  const openSCAModal = useCallback(
    ({
      description,
      onCTAClick,
      onCancel,
    }: {
      description?: string;
      onCTAClick?: () => void;
      onCancel?: () => void;
    }) => {
      if (!isCashAccountActivated) {
        return;
      }

      if (description && description !== customDescription) {
        setCustomDescription(description);
      }

      setDialog({
        width: '555px',
        closable: true,
        noPadding: appConfig.newUiEnabled,
        dialogBody: (
          <CashSCADialogBody
            description={description || customDescription}
            onCancel={() => {
              onCancel?.();
              hideDialog();
            }}
            onCTAClick={() => {
              onCTAClick?.();
              openSCALink();
            }}
          />
        ),
      });
    },
    [appConfig.newUiEnabled, customDescription, hideDialog, isCashAccountActivated, openSCALink, setDialog],
  );

  const closeSCAModal = useCallback(() => {
    hideDialog();
  }, []);

  const onConfirm = useCallback(() => {
    if (isSCALoading) {
      return setIsSCADelayed(true);
    }

    return openSCALink();
  }, [isSCALoading, openSCALink]);

  useEffect(() => {
    if (isSCADelayed && !isSCALoading) {
      setIsSCADelayed(false);
      openSCALink();
    }
  }, [isSCADelayed, isSCALoading, openSCALink]);

  const value = useMemo(
    () => ({
      customDescription,
      onConfirm,
      closeSCAModal,
      isCashAccountActivated,
      isSCARequired,
      openSCAModal,
      openSCALink,
      isSCADelayed,
      isSCALoading,
      getIntergiroSCA,
      refetchSCAIfNeeded,
      refetchSCA,
      data,
    }),
    [
      customDescription,
      onConfirm,
      closeSCAModal,
      isCashAccountActivated,
      isSCARequired,
      isSCALoading,
      openSCAModal,
      openSCALink,
      isSCADelayed,
      getIntergiroSCA,
      refetchSCAIfNeeded,
      refetchSCA,
      data,
    ],
  );

  return value;
};
