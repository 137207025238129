import {DefaultTransition} from 'src/styled/transitions';
import styled, {css} from 'styled-components';

interface ToggleProps {
  disabled?: boolean | undefined;
}

const StyledContainer = styled.div`
  position: relative;
`;

const StyledToggle = styled.input<ToggleProps>`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  appearance: none;
  cursor: pointer;
  background-color: var(--colors-primary-100);
  border-radius: 100px;

  &,
  & + span {
    ${DefaultTransition()};
  }

  & + span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    pointer-events: none;
    background-color: var(--colors-text-onPrimary);
    border-radius: 50%;
    box-shadow: var(--shadow--04dp);
  }

  &:checked {
    background-color: var(--colors-primary-500);

    & + span {
      left: 16px;
    }
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;

      &,
      &:checked {
        background-color: var(--colors-background-darkGrey);
      }

      & + span {
        left: 0;
        background-color: var(--colors-text-onBackgroundUltraLight);
      }
    `}
`;

export {StyledContainer, StyledToggle};
