import {useRef} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';
import {Input} from 'src/atoms/Input/Input';
import {IntRange} from 'type-fest';

import type {InputProps} from 'src/atoms/Input/Input';

import {StyledClearSearch, StyledSearchField, StyledSearchIcon} from './styled';

export interface SearchFieldProps extends InputProps {
  floatingLabel?: boolean;
  spacing?: IntRange<0, 100>;
}

const SearchField = ({spacing = 0, ...props}: SearchFieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div>
      <StyledSearchField $spacing={spacing}>
        <StyledSearchIcon>
          <Icon name="search" size={24} color="var(--colors-text-onBackgroundMedium)" />
        </StyledSearchIcon>

        <Input ref={inputRef} floatingLabel={false} {...props} />

        <StyledClearSearch
          className="clear-search"
          title="Clear search"
          onClick={() => {
            if (inputRef.current) {
              const descriptor = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');
              if (descriptor && descriptor.set) {
                const nativeInputValueSetter = descriptor.set;
                nativeInputValueSetter.call(inputRef.current, '');
              }

              const event = new Event('change', {bubbles: true});
              inputRef.current.dispatchEvent(event);
            }
          }}>
          <Icon name="close-big" size={24} color="var(--colors-primary-500)" />
        </StyledClearSearch>
      </StyledSearchField>
    </div>
  );
};

export {SearchField};
