import Image from 'next/image';
import {useRouter} from 'next/router';

import {Button, Dialog, Icon, Typography} from '@kasta-io/components';

import s from './KycStartVerificationDialog.module.scss';

interface Props {
  type: 'cash' | 'crypto';
  isVisible: boolean;
  toggleDialog: () => void;
}

export const KycStartVerificationDialog = ({type, isVisible, toggleDialog}: Props) => {
  const router = useRouter();

  const navigateToKyc = async (): Promise<void> => {
    toggleDialog();

    if (type === 'cash') {
      await router.replace('/cash/activation');
    }

    if (type === 'crypto') {
      await router.push(`/kyc`);
    }
  };

  return (
    <Dialog open={isVisible} popOver onClose={toggleDialog} closeOnBackdropClick hasCloseButton={false}>
      <div className={s.titleContainer}>
        <Typography variant="h6" as="h6">
          {type === 'crypto'
            ? 'Verify your identity to benefit from ka.app'
            : 'Finish Cash verification for full access'}
        </Typography>
        <Image src="/images/yellow-column.png" width={80} height={80} alt="" quality={100} />
      </div>

      <div className={s.itemsContainer}>
        {type === 'crypto' ? (
          <>
            <Item icon={'wallet'} content={'Open crypto account'} />
            <Item icon={'swap'} content={'Instantly convert currencies'} />
            <Item icon={'send'} content={'Send crypto to friends on Ka.app—free and instant'} />
            <Item icon={'kasta'} content={'Lower fees & earn rewards with Ka.app tiers'} />
          </>
        ) : (
          <>
            <Item icon={'wallet'} content={'Open crypto & euro accounts'} />
            <Item icon={'credit-card'} content={'Personal Visa debit card'} />
            <Item icon={'swap'} content={'Instantly convert currencies'} />
            <Item icon={'send'} content={'Send euros or crypto to friends on Ka.app—free and instant'} />
            <Item icon={'kasta'} content={'Lower fees & earn rewards with Ka.app tiers'} />
          </>
        )}

        <Button className={s.button} onClick={navigateToKyc}>
          Continue to verification
        </Button>
      </div>
    </Dialog>
  );
};

const Item = ({icon, content}: {icon: string; content: string}) => {
  return (
    <div className={s.item}>
      <Icon name={icon} color="var(--colors-text-onBackgroundMedium)" />
      <Typography variant="body2" color="var(--colors-text-onBackgroundMedium)">
        {content}
      </Typography>
    </div>
  );
};
