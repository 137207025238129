import styled, {css} from 'styled-components';

interface FloatingActionButtonProps {
  $badge?: boolean;
}

const StyledFloatingActionButton = styled.button<FloatingActionButtonProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-size: 0;
  color: var(--colors-text-onPrimary);
  cursor: pointer;
  background-color: var(--colors-primary-500);
  border-radius: 50%;
  box-shadow: var(--shadow--06dp);

  &:hover {
    background-color: var(--colors-primary-200);
  }

  &:active,
  &:disabled,
  &[disabled] {
    background-color: var(--colors-primary-100);
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
  }

  ${props =>
    props.$badge &&
    css`
      &:before {
        position: absolute;
        top: 2px;
        right: 2px;
        display: block;
        width: 10px;
        height: 10px;
        background-color: var(--colors-main-secondary);
        filter: drop-shadow(0 0 4px rgba(1, 213, 191, 30%));
        border-radius: 50%;
      }
    `}
`;

export {StyledFloatingActionButton};
